import React, { ReactElement } from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import ChassisAndChassisPoolsEditable from '@client/components/ChassisAndChassisPoolsEditable';
import { chassisByIdSelectorFactory, ChassisPoolEntity, ContainerSize } from '@client/_blessed/store/entities';
import { ChassisProviderEntity } from '@client/_blessed/store/entities/chassisProviders';
import ChassisForm from '../Form/component';

interface Props {
  chassisId: string;
  setChassisIdForEdit: (uuid: string) => void;
  chassisPoolsById: Dictionary<ChassisPoolEntity>;
  chassisProvidersById: Dictionary<ChassisProviderEntity>;
  chassisSizes: ContainerSize[];
  chassisPools: ChassisPoolEntity[];
  chassisProviders: ChassisProviderEntity[];
}

export default function EditableChassis({
  chassisId,
  setChassisIdForEdit,
  chassisPoolsById,
  chassisProvidersById,
  chassisSizes,
  chassisPools,
  chassisProviders
}: Props): ReactElement {
  const chassisInfo = useSelector(chassisByIdSelectorFactory(chassisId));

  return (
    <ChassisAndChassisPoolsEditable uuidOrId={chassisId} setId={setChassisIdForEdit}>
      <>
        <div className="chassisPoolsManagement__title">Update chassis</div>
        <ChassisForm
          btnLabel="Update"
          chassisPoolsById={chassisPoolsById}
          chassisProvidersById={chassisProvidersById}
          chassisSizes={chassisSizes}
          chassisPools={chassisPools}
          chassisProviders={chassisProviders}
          chassis={chassisInfo}
        />
      </>
    </ChassisAndChassisPoolsEditable>
  );
}
