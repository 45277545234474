import { useState } from 'react';
import { api } from '@client/utils/url';
import request from '@client/utils/request';
import { raiseToast } from '@client/components/Toaster';
import SystemToast from '@client/components/SystemToast';
import DropdownSelectInput from '@client/components/DropdownSelectInput';
import normalizeDelimitedTextareaValue from 'app/utils/normalizeDelimitedTextareaValue';
import Button from '@client/components/Button';
import './style.css';
import { ContainerOnHireStatusResponse, TerminalOption } from '../types';

const type = 'containerOnHireStatus';

async function postContainerOnHireStatuses(data: object): Promise<ContainerOnHireStatusResponse> {
  const json = { data: [{ type, attributes: data }] };
  const body = JSON.stringify(json);
  const url = api('/container-on-hire-statuses');
  const opts = { method: 'POST', body, headers: { 'Content-Type': 'application/json' } };
  const result = (await request(url, opts)) as ContainerOnHireStatusResponse;
  return result;
}

interface FormProps {
  terminalOptions: TerminalOption[];
  isFetching: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsFetching(bool: boolean): void;
  // eslint-disable-next-line no-unused-vars
  setErrors(errors: any): void;
  // eslint-disable-next-line no-unused-vars
  setData(data: any): void;
}

function sendSuccessNotification() {
  const message = 'Container(s) successfully on-hired. Please check the response tables for more details.';
  raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message={message} />);
}

function Form(props: FormProps) {
  const { terminalOptions = [], isFetching, setIsFetching, setErrors, setData } = props;
  const [rawContainerNumbers, setRawContainerNumbers] = useState('');
  const normalizedContainerNumbers = normalizeDelimitedTextareaValue(rawContainerNumbers);
  const splitContainerNumbers = normalizedContainerNumbers.split(' ');
  const [terminalUuid, setTerminalUuid] = useState('');
  const isDisabled = !rawContainerNumbers || !terminalUuid;
  const data = { terminalUuid, containerNumbers: splitContainerNumbers, onHireStatus: 'on_hired' };

  return (
    <div className="container-on-hire-status-form">
      <div>
        <DropdownSelectInput
          searchBy="name"
          values={[]}
          multi={false}
          labelField="name"
          options={terminalOptions}
          valueField="uuid"
          onChange={(values: TerminalOption[]) => {
            const id = values[0] ? values[0]?.uuid : '';
            setTerminalUuid(id);
          }}
        />
      </div>
      <div>
        <textarea
          value={rawContainerNumbers}
          placeholder="Enter container numbers here separated by space, comma, or hard return"
          onChange={(e) => {
            const { value } = e.target;
            setRawContainerNumbers(value);
          }}
          aria-label="Container numbers input"
        />
      </div>
      <div>
        <Button
          theme="2"
          label="Update Container On Hire Status"
          loading={isFetching}
          type="button"
          disabled={isDisabled}
          onClick={async () => {
            setIsFetching(true);
            setErrors([]);
            setData([]);
            try {
              const response = await postContainerOnHireStatuses(data);
              const { data: json, errors } = response;
              setData(json);
              setErrors(errors);
              sendSuccessNotification();
            } catch (err) {
              setErrors(err.errors);
            }
            setIsFetching(false);
          }}
        />
      </div>
    </div>
  );
}

export default Form;
