import React from 'react';
import Tag from '@client/components/Tag';
import Button from '@client/components/Button';
import DropdownSelectInput from '@client/components/DropdownSelectInput';
import { ITagProps } from '@client/components/Tag/component';

import './style.css';

const baseClassName = 'EmptyReturnAcceptanceFilters';
const classNames = {
  root: baseClassName,
  tags: `${baseClassName}__tags`,
  inputs: `${baseClassName}__inputs`,
  input: `${baseClassName}__inputs-input`
  // footer: `${baseClassName}__footer`
};

interface EmptyReturnAcceptanceFiltersProps {
  terminalOptions: { name: string | null; value: string }[];
  selectedTerminalOptions: { name: string | null; value: string }[];
  sslOptions: { name: string | null; value: string }[];
  selectedSslOptions: { name: string | null; value: string }[];
  sizeOptions: { name: string | null; value: string }[];
  selectedSizeOptions: { name: string | null; value: string }[];

  // eslint-disable-next-line no-unused-vars
  setTerminalFilters: (items: { name: string | null; value: string }[]) => void;
  // eslint-disable-next-line no-unused-vars
  setSslFilters: (items: { name: string | null; value: string }[]) => void;
  // eslint-disable-next-line no-unused-vars
  setSizeFilters: (items: { name: string | null; value: string }[]) => void;
  clearAllFilters: () => void;
}

function EmptyReturnAcceptanceFilters(props: EmptyReturnAcceptanceFiltersProps) {
  const handleTerminalDropdownChange = (selectedValues: any) => {
    props.setTerminalFilters(selectedValues);
  };

  const handleSslDropdownChange = (selectedValues: any) => {
    props.setSslFilters(selectedValues);
  };

  const handleSizeDropdownChange = (selectedValues: any) => {
    props.setSizeFilters(selectedValues);
  };

  const handleDeleteTerminalFilter = (cProps: ITagProps) => {
    props.setTerminalFilters(props.selectedTerminalOptions.filter((t) => t.value !== cProps.id));
  };

  const handleDeleteSslFilter = (cProps: ITagProps) => {
    props.setSslFilters(props.selectedSslOptions.filter((t) => t.value !== cProps.id));
  };

  const handleDeleteSizeFilter = (cProps: ITagProps) => {
    props.setSizeFilters(props.selectedSizeOptions.filter((t) => t.value !== cProps.id));
  };

  return (
    <div className={classNames.root}>
      <div className={classNames.tags}>
        {props.selectedTerminalOptions.map((item: any, index: number) => (
            <Tag
              key={item.value}
              id={item.value}
              index={index}
              groupName="terminals"
              label={`Terminal: ${item.name}`}
              onDelete={handleDeleteTerminalFilter}
            />
          ))}
        {props.selectedSslOptions.map((item: any, index: number) => (
            <Tag
              key={item.value}
              id={item.value}
              index={index}
              groupName="ssls"
              label={`SSL: ${item.name}`}
              onDelete={handleDeleteSslFilter}
            />
          ))}
        {props.selectedSizeOptions.map((item: any, index: number) => (
            <Tag
              key={item.value}
              id={item.value}
              index={index}
              groupName="sizes"
              label={`Size: ${item.name}`}
              onDelete={handleDeleteSizeFilter}
            />
          ))}
      </div>

      <div className={classNames.inputs}>
        <DropdownSelectInput
          className={classNames.input}
          placeholder="Terminal"
          groupName="terminals"
          values={props.selectedTerminalOptions}
          labelField="name"
          valueField="value"
          searchBy="name"
          options={props.terminalOptions}
          multi
          noContentUpdate
          onChange={handleTerminalDropdownChange}
        />
        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="SSLs"
          groupName="ssls"
          values={props.selectedSslOptions}
          labelField="name"
          valueField="value"
          searchBy="name"
          noContentUpdate
          options={props.sslOptions}
          onChange={handleSslDropdownChange}
        />
        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Sizes"
          groupName="sizes"
          values={props.selectedSizeOptions}
          labelField="name"
          valueField="value"
          searchBy="name"
          options={props.sizeOptions}
          noContentUpdate
          onChange={handleSizeDropdownChange}
        />
        <Button label="Clear All Filters" onClick={props.clearAllFilters} />
      </div>
    </div>
  );
}

export default EmptyReturnAcceptanceFilters;
