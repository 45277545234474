import { useState, useEffect, useCallback } from 'react';
import { api } from '@client/utils/url';
import { Helmet } from 'react-helmet';
import request from '@client/utils/request';
import Button from '@client/components/Button';
import _ from 'lodash';
import { ContainerOnHireStatusResponse, ContainerOnHireStatus } from '../OnHireEntry/features/types';
import DataTable, { formatDate } from './features/DataTable/component';
import './style.css';

export function getFilteredData(data: ContainerOnHireStatus[], searchTerm: string): ContainerOnHireStatus[] {
  if (!searchTerm.trim()) return data;
  return data.filter((x: any) => {
    const capitalizedSearchTerm = searchTerm.toUpperCase();
    if (x.containerNumber.toUpperCase().includes(capitalizedSearchTerm)) return true;
    if (x.terminal.nickname.toUpperCase().includes(capitalizedSearchTerm)) return true;
    if (x.createdByUser.firstName.toUpperCase().includes(capitalizedSearchTerm)) return true;
    if (x.createdByUser.lastName.toUpperCase().includes(capitalizedSearchTerm)) return true;

    const formattedOnHireDate = formatDate(x.updatedAt);
    if (formattedOnHireDate.includes(capitalizedSearchTerm)) return true;

    const formattedValidUntilDate = formatDate(x.validUntilDate);
    if (formattedValidUntilDate.includes(capitalizedSearchTerm)) return true;

    return false;
  });
}

async function getActiveContainerOnHireStatuses(match: string): Promise<ContainerOnHireStatusResponse> {
  const matchQuery = match ? `&match=${match}` : '';
  const url = api(`/v2/container-on-hire-statuses?status=active&limit=200${matchQuery}`);
  const opts = { method: 'GET' };
  const result = (await request(url, opts)) as ContainerOnHireStatusResponse;
  return result;
}

const pageClassName = 'on-hire-list-container';

function OnHireList() {
  const [data, setData] = useState<any>([]);
  const [sort, setSort] = useState<any>({ field: 'onHireDate', direction: 'ASC' });
  const [searchTerm, setSearchTerm] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteContainerOnHireStatus = async ({
    containerNumber,
    terminalUuid
  }: {
    containerNumber: string;
    terminalUuid: string;
  }): Promise<ContainerOnHireStatusResponse> => {
    setIsDeleting(true);
    const url = api('/v2/container-on-hire-statuses');
    const requestData = { data: [{ type: 'containerOnHireStatus', containerNumber, terminalUuid }] };
    const body = JSON.stringify(requestData);
    const opts = { method: 'DELETE', body };
    const result = (await request(url, opts)) as ContainerOnHireStatusResponse;
    const updatedData = data.filter((containerOnHireStatus: ContainerOnHireStatus) => {
      const matchContainer = containerOnHireStatus.containerNumber === containerNumber;
      const matchTerminal = containerOnHireStatus.terminalUuid === terminalUuid;
      const matchRow = matchContainer && matchTerminal;
      // remove matched row from table
      return !matchRow;
    });
    setData(updatedData);
    setIsDeleting(false);
    return result;
  };

  const fetchStatuses = async (searchTermInput: string) => {
    setIsFetching(true);
    const response = await getActiveContainerOnHireStatuses(searchTermInput);
    setData(response.data);
    setIsFetching(false);
  };
  const debouncedFetchStatuses = useCallback(_.debounce(fetchStatuses, 1000), []);
  const onChangeHandler = (searchTermInput: string) => {
    setSearchTerm(searchTermInput);
    debouncedFetchStatuses(searchTermInput);
  };

  useEffect(() => {
    fetchStatuses('');
  }, []);

  const toggleSort = (column: string) => {
    if (column === sort.field) {
      setSort({
        ...sort,
        direction: sort.direction === 'ASC' ? 'DESC' : 'ASC'
      });
    } else {
      setSort({
        field: column,
        direction: 'ASC'
      });
    }
  };

  const sortData = (a: ContainerOnHireStatus, b: ContainerOnHireStatus) => {
    let aVal = a[sort.field as keyof ContainerOnHireStatus];
    let bVal = b[sort.field as keyof ContainerOnHireStatus];
    if (sort.field === 'onHiredByUserFirstName') {
      aVal = a.createdByUser.firstName;
      bVal = b.createdByUser.firstName;
    }
    if (sort.field === 'terminalNickname') {
      aVal = a.terminal.nickname;
      bVal = b.terminal.nickname;
    }
    if (sort.direction === 'ASC') {
      return (aVal as string) < (bVal as string) ? -1 : 1;
    }
    return (aVal as string) > (bVal as string) ? -1 : 1;
  };

  const sortedData = data.sort(sortData);

  const countJsx = data.length > 0 ? `(${data.length})` : null;

  return (
    <>
      <Helmet title="On-Hire List" />
      <div className="on-hire-list-container">
        <h1 className={`${pageClassName}__h1`}>Active On-Hired Containers {countJsx}</h1>
        <div className={`${pageClassName}__filters`}>
          <Button
            theme="2"
            label="Reset Filters"
            onClick={() => {
              onChangeHandler('');
            }}
          />
          Match String:
          <input
            className="reset-form__input match-filter"
            value={searchTerm}
            onChange={(e) => {
              const searchTermInput = e.target.value;
              onChangeHandler(searchTermInput);
            }}
            aria-label="Match String input"
          />
        </div>

        <DataTable
          data={sortedData}
          onDelete={deleteContainerOnHireStatus}
          sort={sort}
          onSort={toggleSort}
          isFetching={isFetching}
          isDeleting={isDeleting}
        />
      </div>
    </>
  );
}

export default OnHireList;
