import './style.css';

interface OnHireError {
  containerNumber: string;
  message: string;
}

interface ErrorTableProps {
  errors: OnHireError[];
}

function ErrorTable(props: ErrorTableProps) {
  const errors = props.errors || [];
  if (errors.length === 0) return null;
  return (
    <div className="ErrorTable">
      <div className="table-title">
        <span>Does not meet criteria:</span>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Container</th>
            <th>Error Message</th>
          </tr>
        </thead>
        <tbody>
          {errors.map((error) => {
            const { containerNumber, message } = error;
            return (
              <tr key={containerNumber}>
                <td>{containerNumber}</td>
                <td>{message}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ErrorTable;
