import React, { useEffect, useState } from 'react';

import DropdownSelectInput from '@client/components/DropdownSelectInput';
import Button from '@client/components/Button';
import Tag from '@client/components/Tag';

import './style.css';

interface DualTransFindersFilterProps {
  terminals: any;
  legStatuses: any;
  shippers: any;
  carriers: any;
  containerSizes: any;
  // eslint-disable-next-line no-unused-vars
  onFilterChange?: (filters: any) => any;
}

function DualTransFindersFilter(props: DualTransFindersFilterProps) {
  const baseClassName = 'DualTransFinderFilters';
  const classNames = {
    root: baseClassName,
    tags: `${baseClassName}__tags`,
    inputs: `${baseClassName}__inputs`,
    input: `${baseClassName}__inputs-input`,
    footer: `${baseClassName}__footer`
  };

  const [state, setState]: any = useState({
    filters: {
      terminals: [],
      legStatuses: [],
      shippers: [],
      carriers: [],
      containerSizes: []
    },
    dropDownRefs: {
      terminals: null,
      legStatuses: null,
      shippers: null,
      carriers: null,
      containerSizes: null
    }
  });

  const handleDropdownChange = (selectedValues: any, cProps: any) => {
    state.dropDownRefs[cProps.groupName] = selectedValues;

    const values = selectedValues.map((item: any) => item.value);

    setState((prevState: any) => ({
        ...prevState,
        filters: {
          ...state.filters,
          [cProps.groupName]: values
        }
      }));
  };

  const handleClearFiltersClick = () => {
    state.dropDownRefs.terminals?.splice(0, state.dropDownRefs.terminals?.length);
    state.dropDownRefs.legStatuses?.splice(0, state.dropDownRefs.legStatuses?.length);
    state.dropDownRefs.shippers?.splice(0, state.dropDownRefs.shippers?.length);
    state.dropDownRefs.carriers?.splice(0, state.dropDownRefs.carriers?.length);
    state.dropDownRefs.containerSizes?.splice(0, state.dropDownRefs.containerSizes?.length);

    setState((prevState: any) => ({
        ...prevState,
        filters: {}
      }));
  };

  const handleDeleteFilter = (cProps: any) => {
    state.dropDownRefs[cProps.groupName]?.splice(cProps.index, 1);
    const groupFilters = Array.isArray(state.filters[cProps.groupName]) ? [...state.filters[cProps.groupName]] : [];
    groupFilters.splice(cProps.index, 1);

    setState((prevState: any) => ({
        ...prevState,
        filters: {
          ...state.filters,
          [cProps.groupName]: groupFilters
        }
      }));
  };

  useEffect(() => {
    if (props.onFilterChange) {
      props.onFilterChange(state.filters);
    }
  }, [state.filters]);

  return (
    <div className={classNames.root}>
      <div className={classNames.tags}>
        {state.filters.terminals?.map((item: any, index: number) => (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="terminals"
              label={`Terminal: ${item}`}
              onDelete={handleDeleteFilter}
            />
          ))}

        {state.filters.legStatuses?.map((item: any, index: number) => (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="legStatuses"
              label={`Leg Status: ${item}`}
              onDelete={handleDeleteFilter}
            />
          ))}

        {state.filters.shippers?.map((item: any, index: number) => (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="shippers"
              label={`Shipper: ${item}`}
              onDelete={handleDeleteFilter}
            />
          ))}

        {state.filters.carriers?.map((item: any, index: number) => (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="carriers"
              label={`Carrier: ${item}`}
              onDelete={handleDeleteFilter}
            />
          ))}

        {state.filters.containerSizes?.map((item: any, index: number) => (
            <Tag
              key={item}
              id={item.label}
              index={index}
              groupName="containerSizes"
              label={`Size: ${item}`}
              onDelete={handleDeleteFilter}
            />
          ))}
      </div>
      <div className={classNames.inputs}>
        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Terminal"
          groupName="terminals"
          values={state.filters.terminals}
          labelField="name"
          valueField="value"
          options={props.terminals}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Leg Status"
          groupName="legStatuses"
          values={state.filters.legStatuses}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.legStatuses}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Shipper"
          groupName="shippers"
          values={state.filters.shippers}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.shippers}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Carrier"
          groupName="carriers"
          values={state.filters.carriers}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.carriers}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <DropdownSelectInput
          className={classNames.input}
          multi
          placeholder="Container Size"
          groupName="containerSizes"
          values={state.filters.containerSizes}
          labelField="name"
          searchBy="name"
          valueField="value"
          options={props.containerSizes}
          noContentUpdate
          onChange={handleDropdownChange}
        />

        <div className={classNames.footer}>
          <Button theme="2" label="Clear Filters" onClick={handleClearFiltersClick} />
        </div>
      </div>
    </div>
  );
}
export default DualTransFindersFilter;
