// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.on-hire-list-container {
  padding: 40px;
}

  .on-hire-list-container__h1 {
    padding-bottom: 30px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }

  .on-hire-list-container__filters {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 10px;
  }

  .on-hire-list-container__filters .match-filter {
      width: 300px;
      margin-right: 50px;
      margin-left: 10px;
    }

  .on-hire-list-container__filters .Button {
      margin-right: 10px;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/OnHireList/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AA0Bf;;EAxBE;IACE,oBAAoB;IACpB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,oBAAoB;EAWtB;;EATE;MACE,YAAY;MACZ,kBAAkB;MAClB,iBAAiB;IACnB;;EAEA;MACE,kBAAkB;IACpB","sourcesContent":[".on-hire-list-container {\n  padding: 40px;\n\n  &__h1 {\n    padding-bottom: 30px;\n    margin-bottom: 0;\n    font-size: 20px;\n    font-weight: 600;\n    margin-top: 0;\n  }\n\n  &__filters {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    padding-bottom: 10px;\n\n    .match-filter {\n      width: 300px;\n      margin-right: 50px;\n      margin-left: 10px;\n    }\n\n    .Button {\n      margin-right: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
