import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const sliceId = 'emptyReturnAcceptancePage';

export interface EmptyReturnAcceptanceState {
  filters: {
    terminalUuids: string[];
    sslUuids: string[];
    sizeUuids: string[];
  };
}

const emptyFilters = {
  terminalUuids: [],
  sslUuids: [],
  sizeUuids: []
};

const getInitialState = (): EmptyReturnAcceptanceState => ({
  filters: { ...emptyFilters }
});

const slice = createSlice({
  name: sliceId,
  initialState: getInitialState(),
  reducers: {
    setTerminalFilters: (state, action: PayloadAction<string[]>) => {
      state.filters.terminalUuids = action.payload;

      return state;
    },
    setSslFilters: (state, action: PayloadAction<string[]>) => {
      state.filters.sslUuids = action.payload;

      return state;
    },
    setSizeFilters: (state, action: PayloadAction<string[]>) => {
      state.filters.sizeUuids = action.payload;

      return state;
    },
    clearAllFilters: (state) => {
      state.filters = { ...emptyFilters };

      return state;
    }
  }
});

export const { setTerminalFilters, setSslFilters, setSizeFilters, clearAllFilters } = slice.actions;
export default slice;
