import { createSelector } from 'reselect';
import {
  selectEmptyReturnAcceptanceTerminals,
  selectTerminals,
  selectShippers,
  selectAllContainerSizes,
  selectEmptyReturnAcceptanceSsls
} from '@client/_blessed/store/entities';

import { getDepartmentDisplayName } from '@client/utils/data-processing/department';
import { sliceId, EmptyReturnAcceptanceState } from './index';
import { sliceId as adminSliceId } from '../../../store/index';

export const slice = (state: any): EmptyReturnAcceptanceState => state[adminSliceId][sliceId];

export const filters = createSelector(slice, (state) => state.filters);

export const filterTerminalsUuids = createSelector(filters, (stateFilters) => stateFilters.terminalUuids);

export const filterSslUuids = createSelector(filters, (stateFilters) => stateFilters.sslUuids);

export const filterSizes = createSelector(filters, (stateFilters) => stateFilters.sizeUuids);

export const terminalFilterOptions = createSelector(
  selectTerminals,
  selectEmptyReturnAcceptanceTerminals,
  (terminals, availableEmptyReturnTerminals) => terminals
      .filter((t) => availableEmptyReturnTerminals.includes(t.uuid))
      .map((t) => ({ name: getDepartmentDisplayName(t), value: t.uuid }))
      .sort((a, b) => a.name!.localeCompare(b.name!))
);

export const shippingLineFilterOptions = createSelector(
  selectShippers,
  selectEmptyReturnAcceptanceSsls,
  (ssls, availableEmptyReturnSsls) =>
    ssls
      .filter((s) => availableEmptyReturnSsls.includes(s.uuid))
      .map((s) => ({ name: getDepartmentDisplayName(s), value: s.uuid }))
      .sort((a, b) => a.name!.localeCompare(b.name!))
);

export const sizeFilterOptions = createSelector(selectAllContainerSizes, (sizes) => sizes
    .sort((a, b) => a.length - b.length)
    .map((s) => ({ name: `${s.length}${s.type.toUpperCase()}`, value: s.uuid })));

export const selectedTerminalFilterOptions = createSelector(
  filterTerminalsUuids,
  terminalFilterOptions,
  (uuids, terminals) => terminals.filter((t) => uuids.includes(t.value))
);

export const selectedSslFilterOptions = createSelector(filterSslUuids, shippingLineFilterOptions, (uuids, ssls) => ssls.filter((s) => uuids.includes(s.value)));

export const selectedSizeFilterOptions = createSelector(filterSizes, sizeFilterOptions, (uuids, sizes) => sizes.filter((s) => uuids.includes(s.value)));

export const filteredTerminalsForPage = createSelector(
  selectTerminals,
  selectedTerminalFilterOptions,
  selectEmptyReturnAcceptanceTerminals,
  (terminals, options, emptyReturnAcceptanceTerminals) =>
    terminals
      .filter((t) => (options.length ? options.map((o) => o.value).includes(t.uuid) : true))
      .filter((t) => emptyReturnAcceptanceTerminals.includes(t.uuid))
      .sort((a, b) => (a.nickname || a.name).localeCompare(b.nickname || b.name))
);

export const filteredSslsForPage = createSelector(
  selectShippers,
  selectedSslFilterOptions,
  selectEmptyReturnAcceptanceSsls,
  (ssls, options, emptyReturnAcceptanceSsls) =>
    ssls
      .filter((t) => (options.length ? options.map((o) => o.value).includes(t.uuid) : true))
      .filter((t) => emptyReturnAcceptanceSsls.includes(t.uuid))
      .sort((a, b) => (a.nickname || a.name).localeCompare(b.nickname || b.name))
);

export const filteredSizesForPage = createSelector(
  selectAllContainerSizes,
  selectedSizeFilterOptions,
  (sizes, options) =>
    sizes
      .filter((t) => (options.length ? options.map((o) => o.value).includes(t.uuid) : true))
      .sort((a, b) => a.type.localeCompare(b.type))
);
