export const chassisPoolsLabel = {
  ABBREVIATION: 'Abbreviation',
  BADGE_NAME: 'Badge name',
  BADGE_COLOR: 'Badge color',
  NAME: 'Name',
  DESCRIPTION: 'Description'
};

export const chassisPools = {
  ABBREVIATION: 'abbreviation',
  BADGE_NAME: 'badgeName',
  BADGE_COLOR: 'badgeColor',
  NAME: 'name',
  DESCRIPTION: 'description'
};
