// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.on-hire-list-data-table-wrapper > * {
    margin-bottom: 30px;
  }
  .on-hire-list-data-table-wrapper .table {
    border: 1px solid var(--color-grayscale-5);
    width: 100%;
    text-align: left;
  }
  .on-hire-list-data-table-wrapper .table th {
      font-weight: 500;
      background: var(--color-grayscale-2);
    }
  .on-hire-list-data-table-wrapper .table th,
    .on-hire-list-data-table-wrapper .table td {
      padding: 15px 20px;
    }
  .on-hire-list-data-table-wrapper .table > tr {
      border: 1px solid #ccc;
    }
  .on-hire-list-data-table-wrapper .table .trash-icon {
      width: 16px;
    }
  .on-hire-list-data-table-wrapper .table .trash-icon:hover {
        cursor: pointer;
        color: #525b62;
      }
  .on-hire-list-data-table-wrapper .CopyButton {
    display: inline;
    position: relative;
    left: 4px;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }
  .on-hire-list-data-table-wrapper td:hover .CopyButton, .on-hire-list-data-table-wrapper *:hover > .CopyButton {
    opacity: 1;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/OnHireList/features/DataTable/style.css"],"names":[],"mappings":"AACE;IACE,mBAAmB;EACrB;EAEA;IACE,0CAA0C;IAC1C,WAAW;IACX,gBAAgB;EAwBlB;EAtBE;MACE,gBAAgB;MAChB,oCAAoC;IACtC;EAEA;;MAEE,kBAAkB;IACpB;EAEA;MACE,sBAAsB;IACxB;EAEA;MACE,WAAW;IAMb;EAJE;QACE,eAAe;QACf,cAAc;MAChB;EAIJ;IACE,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,mCAAmC;EACrC;EAEA;IACE,UAAU;EACZ","sourcesContent":[".on-hire-list-data-table-wrapper {\n  & > * {\n    margin-bottom: 30px;\n  }\n\n  .table {\n    border: 1px solid var(--color-grayscale-5);\n    width: 100%;\n    text-align: left;\n\n    & th {\n      font-weight: 500;\n      background: var(--color-grayscale-2);\n    }\n\n    & th,\n    td {\n      padding: 15px 20px;\n    }\n\n    & > tr {\n      border: 1px solid #ccc;\n    }\n\n    & .trash-icon {\n      width: 16px;\n\n      &:hover {\n        cursor: pointer;\n        color: #525b62;\n      }\n    }\n  }\n\n  .CopyButton {\n    display: inline;\n    position: relative;\n    left: 4px;\n    opacity: 0;\n    transition: opacity .2s ease-in-out;\n  }\n\n  td:hover .CopyButton, *:hover > .CopyButton {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
