import { Chassis, ChassisPoolEntity, ChassisProviderEntity, ContainerSize } from '@client/_blessed/store/entities';

interface HashMapChassisSize {
  [uuid: string]: Array<string | number>;
}

interface HashMapChassisPoolName {
  [uuid: string]: string | null;
}

interface HashMapChassisProviderName {
  [uuid: string]: string | null;
}

function hashMapChassisSize(chassisSizes: ContainerSize[]): HashMapChassisSize {
  const map: HashMapChassisSize = {};
  chassisSizes.forEach(({ uuid, type, length }: ContainerSize) => {
    if (map[uuid as keyof ContainerSize] === undefined) {
      map[uuid] = [type, length];
    }
  });
  return map;
}

function hashMapChassisPoolName(chassisPool: ChassisPoolEntity[]): HashMapChassisPoolName {
  const map: HashMapChassisPoolName = {};
  chassisPool.forEach(({ uuid, name }) => {
    if (map[uuid as keyof ChassisPoolEntity] === undefined) {
      map[uuid] = name;
    }
  });
  return map;
}

function hashMapChassisProviderName(chassisProvider: ChassisProviderEntity[]): HashMapChassisProviderName {
  const map: HashMapChassisProviderName = {};
  chassisProvider.forEach(({ uuid, name }) => {
    if (map[uuid as keyof ChassisProviderEntity] === undefined) {
      map[uuid] = name;
    }
  });
  return map;
}

export function convertChassisPoolAndSizesAndProvidersToName(
  allChassis: Chassis[],
  chassisPools: ChassisPoolEntity[],
  chassisProviders: ChassisProviderEntity[],
  chassisSizes: ContainerSize[]
): Chassis[] {
  const hashedChassisSizes = hashMapChassisSize(chassisSizes);
  const hashedChassisPoolName = hashMapChassisPoolName(chassisPools);
  const hashedChassisProviderName = hashMapChassisProviderName(chassisProviders);

  const result = allChassis.map((chassis) => {
    const actualSizes: string[] = [];
    const copy = { ...chassis } as any;

    const { chassisPoolUuid, chassisPoolUuidOverride, chassisProviderUuid, chassisSupportedSize } = chassis || '';
    if (chassisPoolUuid && chassisPoolUuid !== null) {
      copy.chassisPoolUuid = hashedChassisPoolName[chassisPoolUuid];
    } else {
      copy.chassisPoolUuid = 'N/A';
    }

    if (chassisPoolUuidOverride && chassisPoolUuidOverride !== null) {
      copy.chassisPoolUuidOverride = hashedChassisPoolName[chassisPoolUuidOverride];
    } else {
      copy.chassisPoolUuidOverride = 'N/A';
    }

    if (chassisProviderUuid && chassisProviderUuid !== null) {
      copy.chassisProviderUuid = hashedChassisProviderName[chassisProviderUuid];
    } else {
      copy.chassisProviderUuid = 'N/A';
    }

    if (chassisSupportedSize.length < 1) {
      copy.chassisSupportedSize = 'N/A';
    } else if (Array.isArray(chassisSupportedSize)) {
      chassisSupportedSize.forEach((uuid: string) => {
        const [type, length] = hashedChassisSizes[uuid];
        actualSizes.push(`${length}${type} `);
        copy.chassisSupportedSize = actualSizes.join(',');
      });
    }
    return copy;
  });
  return result;
}
