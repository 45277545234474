// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table-wrapper > * {
    margin-bottom: 30px;
  }
  .data-table-wrapper .table {
    border: 1px solid var(--color-grayscale-5);
    width: 100%;
    text-align: left;
  }
  .data-table-wrapper .table th {
      font-weight: 500;
      background: var(--color-grayscale-2);
    }
  .data-table-wrapper .table th,
    .data-table-wrapper .table td {
      padding: 15px 20px;
    }
  .data-table-wrapper .table > tr {
      border: 1px solid #ccc;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/OnHireEntry/features/DataTable/style.css"],"names":[],"mappings":"AACE;IACE,mBAAmB;EACrB;EAEA;IACE,0CAA0C;IAC1C,WAAW;IACX,gBAAgB;EAelB;EAbE;MACE,gBAAgB;MAChB,oCAAoC;IACtC;EAEA;;MAEE,kBAAkB;IACpB;EAEA;MACE,sBAAsB;IACxB","sourcesContent":[".data-table-wrapper {\n  & > * {\n    margin-bottom: 30px;\n  }\n\n  .table {\n    border: 1px solid var(--color-grayscale-5);\n    width: 100%;\n    text-align: left;\n\n    & th {\n      font-weight: 500;\n      background: var(--color-grayscale-2);\n    }\n\n    & th,\n    td {\n      padding: 15px 20px;\n    }\n\n    & > tr {\n      border: 1px solid #ccc;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
