import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { isEmpty, debounce } from 'lodash';
import {
  fetchChassisPools,
  ChassisPoolEntity,
  selectChassisPoolsById,
  deleteBulkChassisPool,
  selectChassisPoolByTermFactory
} from '@client/_blessed/store/entities/chassisPools';
import Button from '@client/components/Button';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import ChassisAndChassisPoolDelete from '@client/components/ChassisAndChassisPoolDelete/component';
import ChassisPoolsTable from './features/ChassisPoolsTable/component';
import ChassisPoolsForm from './features/Form/component';
import EditableChassisPool from './features/EditableChassisPool/component';
import { HandleChassisPoolDelete } from './types';

import './styles.css';

export interface ChassisPoolForDelete {
  uuid: boolean;
}

function ChassisPoolsManagement() {
  const baseClassName = 'chassisPoolsManagement';

  const [chassisPoolsForDelete, setChassisPoolsForDelete] = useState<ChassisPoolForDelete>({} as ChassisPoolForDelete);
  const [chassisPoolUuidForEdit, setChassisPoolUuidForEdit] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialouge, setDialouge] = useState(false);

  const dispatch = useAppDispatch();
  const chassisPoolById = useSelector(selectChassisPoolsById);
  const chassisPoolsBySearchQuery = useSelector(selectChassisPoolByTermFactory(searchQuery));

  useEffect(() => {
    dispatch(fetchChassisPools());
  }, []);

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  const debouncedSearchQuery = useCallback(debounce(handleSearchQuery, 1000), []);

  const handleOnDeleteChange: HandleChassisPoolDelete = (uuid, isSetForDelete) => {
    const map = {} as ChassisPoolForDelete;
    const previousChassisPoolForDelete = chassisPoolsForDelete;
    map[uuid as keyof ChassisPoolForDelete] = isSetForDelete;
    setChassisPoolsForDelete({ ...previousChassisPoolForDelete, ...map });
  };

  const handleBulkDelete = async () => {
    const chassisPoolUuids = Object.keys(chassisPoolsForDelete).filter(
      (uuid) => chassisPoolsForDelete[uuid as keyof ChassisPoolForDelete]
    );

    await dispatch(deleteBulkChassisPool(chassisPoolUuids));
    setChassisPoolsForDelete({} as ChassisPoolForDelete);
    setDialouge(false);
  };

  const handleDeleteDialouge = () => {
    if (openDialouge) {
      setDialouge(false);
    } else {
      setDialouge(true);
    }
  };

  return (
    <>
      <Helmet title="Chassis Pools Management" />
      <div className={baseClassName}>
        <div className={`${baseClassName}__content`}>
          <section id="newChassisPool">
            <h1 className={`${baseClassName}__title`}>Create new Chassis Pool</h1>
            <ChassisPoolsForm btnLabel="Create New" />
          </section>

          <ChassisPoolsTable
            chassisPools={chassisPoolsBySearchQuery}
            handleOnDeleteChange={handleOnDeleteChange}
            setChassisPoolUuidForEdit={setChassisPoolUuidForEdit}
            chassisPoolUuidForEdit={chassisPoolUuidForEdit}
            setSearchQuery={debouncedSearchQuery}
            searchQuery={searchQuery}
          />
          <EditableChassisPool
            chassisPoolUuid={chassisPoolUuidForEdit}
            setChassisPoolUuidForEdit={setChassisPoolUuidForEdit}
          />

          {!isEmpty(chassisPoolsForDelete) && Object.values(chassisPoolsForDelete).some((item) => item) && (
            <Button
              disabled={false}
              theme="2"
              label="Confirm Chassis Pool Deletion"
              loading={false}
              className={`${baseClassName}__bulk-delete-btn`}
              onClick={handleDeleteDialouge}
            />
          )}

          <ChassisAndChassisPoolDelete
            open={openDialouge}
            itemsForDelete={Object.keys(chassisPoolsForDelete).reduce((acc: Array<string | undefined>, key: string) => {
              // show only chassis pool ready for delete
              if (chassisPoolsForDelete[key as keyof ChassisPoolForDelete]) {
                acc.push(chassisPoolById[key as keyof ChassisPoolEntity]?.abbreviation);
              }
              return acc;
            }, [])}
            confirmDelete={handleBulkDelete}
            cancelDelete={handleDeleteDialouge}
            message="You are deleting the following chassis."
          />
        </div>
      </div>
    </>
  );
}

export default ChassisPoolsManagement;
