import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Button from '@client/components/Button/component';
import { Dictionary } from '@reduxjs/toolkit';
import EditableField from '@client/components/EditableField';
import { isEmpty } from 'lodash';
import { Chassis, ChassisPoolEntity, ContainerSize, putBulkChassis } from '@client/_blessed/store/entities';
import TextInput from '@client/components/TextInput/component';
import Checkbox from '@client/components/Checkbox/component';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { ChassisProviderEntity } from '@client/_blessed/store/entities/chassisProviders';
import { chassisLabels, chassisAttributes } from '../../consts';

import './styles.css';

interface Props {
  chassisPoolsById: Dictionary<ChassisPoolEntity>;
  chassisProvidersById: Dictionary<ChassisProviderEntity>;
  chassisSizes: ContainerSize[];
  chassisPools: ChassisPoolEntity[];
  chassisProviders: ChassisProviderEntity[];
  chassis?: Chassis;
  btnLabel: string;
}

const initialState = {
  id: '',
  chassisPoolUuid: '',
  chassisPoolUuidOverride: null,
  chassisProviderUuid: '',
  triaxle: false,
  extendable: false,
  inRotation: false,
  lastPoolUpdated: null,
  lastProviderUpdated: null,
  isPoolScraped: false,
  isProviderScraped: false
} as Chassis;

const createSupportedSizesMap = (supportedSizes: string[]) => {
  const map = {} as any;
  supportedSizes.forEach((size: any) => {
    if (supportedSizes[size] === undefined) {
      map[size] = true;
    }
  });
  return map;
};

export default function ChassisForm({
  chassisPoolsById,
  chassisProvidersById,
  chassisPools,
  chassisProviders,
  chassis,
  chassisSizes,
  btnLabel
}: Props) {
  const [newChassis, setForm] = useState(initialState);
  const [supportedSizes, setSupportedSize] = useState({} as { [uuid: string]: Boolean });
  const [disableChassisIdField, setDisableChassisIdField] = useState(false);

  const baseClassName = 'chassisManagementForm';
  const dispatch = useAppDispatch();

  const featureFlags = useFlags();
  const { showChassisProviderOnHub } = featureFlags;

  useEffect(() => {
    if (!isEmpty(chassis) && chassis) {
      const { chassisSupportedSize } = chassis;
      const supportedSizesMap = createSupportedSizesMap(chassisSupportedSize);

      setSupportedSize({ ...supportedSizes, ...supportedSizesMap });
      setDisableChassisIdField(true);
      setForm(chassis);

      return () => setSupportedSize({});
    }
  }, [chassis]);

  const handleInputChange = async (value: string, dataProps: any) => {
    const { name, id } = dataProps;

    setForm((prevState) => ({
      ...prevState,
      [name || id]: ['no_chassis_pool', 'no_chassis_provider'].includes(value) ? null : value
    }));
  };

  const handleSupportedSizeChange = (value: string, dataProps: any) => {
    const { id } = dataProps;
    if (supportedSizes[value] === undefined) {
      const map = { [id]: value };
      setSupportedSize({ ...supportedSizes, ...map });
    }
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const chassisSupportedSize = Object.keys(supportedSizes).filter((uuid) => supportedSizes[uuid]);
    const payload = { ...newChassis, chassisSupportedSize };

    const result = await dispatch(putBulkChassis([payload])).unwrap();

    if (result?.payload?.length > 0) {
      setForm(initialState);
    }
  };

  const chassisPoolDropdownOptions = useMemo(() => {
    const options = [{ name: 'No Chassis Pool', uuid: 'no_chassis_pool' } as ChassisPoolEntity];

    if (chassisPools) {
      options.push(...chassisPools);
    }

    return options;
  }, []);

  const chassisProviderDropdownOptions = useMemo(() => {
    const options = [{ name: 'No Chassis Provider', uuid: 'no_chassis_provider' } as ChassisProviderEntity];

    if (chassisProviders) {
      options.push(...chassisProviders);
    }

    return options;
  }, [chassisProviders]);

  const { id, chassisPoolUuid, chassisPoolUuidOverride, chassisProviderUuid, triaxle, extendable, inRotation } =
    newChassis;

  return (
    <div className={baseClassName}>
      <form className={`${baseClassName}__form`} onSubmit={handleSubmit}>
        <div className={`${baseClassName}__text-input-wrapper`}>
          <label className={`${baseClassName}__label`}>{chassisLabels.ID}</label>
          <TextInput
            className={`${baseClassName}__text-input`}
            name={chassisAttributes.ID}
            value={id}
            disabled={disableChassisIdField}
            onChange={handleInputChange}
            required
          />

          <label className={`${baseClassName}__label`}>{chassisLabels.CHASSIS_POOL_UUID}</label>
          <EditableField
            className={`${baseClassName}__text-input`}
            editable
            name={chassisAttributes.CHASSIS_POOL_UUID}
            type="dropdown"
            placeholder="Select chassis pool"
            value={chassisPoolUuid}
            inputProps={{
              dropdownHandle: true,
              labelField: 'name',
              valueField: 'uuid',
              options: chassisPoolDropdownOptions
            }}
            dataId={chassisAttributes.CHASSIS_POOL_UUID}
            dataField="chassisPoolUuid"
            dataResourceType="chassis"
            displayFormatter={(value: any) => chassisPoolsById[value]?.name}
            onChange={handleInputChange}
          />

          {btnLabel !== 'Create Chassis' && (
            <>
              <label className={`${baseClassName}__label`}>{chassisLabels.CHASSIS_POOL_UUID_OVERRIDE}</label>
              <EditableField
                className={`${baseClassName}__text-input`}
                editable
                name={chassisAttributes.CHASSIS_POOL_UUID_OVERRIDE}
                type="dropdown"
                placeholder="Select chassis pool override"
                value={chassisPoolUuidOverride}
                inputProps={{
                  dropdownHandle: true,
                  labelField: 'name',
                  valueField: 'uuid',
                  options: chassisPoolDropdownOptions
                }}
                dataId={chassisAttributes.CHASSIS_POOL_UUID_OVERRIDE}
                dataField="chassisPoolUuidOverride"
                dataResourceType="chassis"
                displayFormatter={(value: any) => chassisPoolsById[value]?.name}
                onChange={handleInputChange}
              />
            </>
          )}

          {showChassisProviderOnHub ? (
            <label className={`${baseClassName}__label`}>{chassisLabels.CHASSIS_PROVIDER_UUID}</label>
          ) : (
            <>&nbsp;</>
          )}

          {showChassisProviderOnHub ? (
            <EditableField
              className={`${baseClassName}__text-input`}
              editable
              name={chassisAttributes.CHASSIS_PROVIDER_UUID}
              type="dropdown"
              placeholder="Select chassis provider"
              value={chassisProviderUuid}
              inputProps={{
                dropdownHandle: true,
                labelField: 'name',
                valueField: 'uuid',
                options: chassisProviderDropdownOptions
              }}
              dataId={chassisAttributes.CHASSIS_PROVIDER_UUID}
              dataField="chassisProviderUuid"
              dataResourceType="chassis"
              displayFormatter={(value: any) => chassisProvidersById[value]?.name}
              onChange={handleInputChange}
            />
          ) : (
            <>&nbsp;</>
          )}
        </div>
        <div className={`${baseClassName}__chassis-size-wrapper`}>
          <label className={`${baseClassName}__label`}>{chassisLabels.SUPPORTED_SIZE}</label>
          <div className={`${baseClassName}__chassis-size`}>
            {chassisSizes.length > 0 &&
              chassisSizes
                .sort((a, b) => {
                  if (a.length > b.length) {
                    return 1;
                  }

                  if (a.length < b.length) {
                    return -1;
                  }

                  return 0;
                })
                .map((chassisSize, i) => {
                  const { type, length, uuid } = chassisSize;
                  return (
                    <Checkbox
                      className={`${baseClassName}__chassis-size-checkbox`}
                      checked={!!supportedSizes[uuid]}
                      id={uuid}
                      // eslint-disable-next-line react/no-array-index-key
                      key={uuid + i}
                      label={`${length}${type}`}
                      onToggle={handleSupportedSizeChange}
                    />
                  );
                })}
          </div>
        </div>

        <div className={`${baseClassName}__checkbox-group`}>
          <Checkbox
            checked={triaxle}
            id={chassisAttributes.TRIAXLE}
            label={chassisLabels.TRIAXLE}
            onToggle={handleInputChange}
          />
          <Checkbox
            checked={extendable}
            id={chassisAttributes.EXTENDABLE}
            label={chassisLabels.EXTENDABLE}
            onToggle={handleInputChange}
          />
          <Checkbox
            checked={inRotation}
            id={chassisAttributes.IN_ROTATION}
            label={chassisLabels.IN_ROTATION}
            onToggle={handleInputChange}
          />
        </div>
        <Button disabled={false} theme="2" label={btnLabel} loading={false} type="button" submit />
      </form>
    </div>
  );
}
