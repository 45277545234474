import moment from 'moment';
import TrashIcon from '@components/deprecatedTookit/icons/fa/regular/TrashAltIcon';
import IconButton from '@client/components/IconButton';
import CopyButton from '@client/components/CopyButton';
import { ContainerOnHireStatus } from '../../../OnHireEntry/features/types';

import './style.css';

interface DataTableProps {
  data: ContainerOnHireStatus[];
  isFetching: boolean;
  isDeleting: boolean;
  sort: { field: string; direction: string };

  onDelete(arg: { containerNumber: string; terminalUuid: string }): void;

  onSort: (column: string) => void;
}

export const formatDate = (date: string | null) => moment(date).format('MM/DD');

function DataTable(props: DataTableProps) {
  const { data = [], isFetching, isDeleting, onDelete } = props;

  if (isFetching) return <div>Loading...</div>;

  if (data.length === 0) {
    return <div>There are currently no active on-hired containers right now.</div>;
  }
  return (
    <div className="on-hire-list-data-table-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>
              <button type="button" onClick={() => props.onSort('containerNumber')}>
                Container {props.sort.field === 'containerNumber' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>
              <button type="button" onClick={() => props.onSort('terminalNickname')}>
                Terminal {props.sort.field === 'terminalNickname' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>
              <button type="button" onClick={() => props.onSort('updatedAt')}>
                On-Hired Date {props.sort.field === 'updatedAt' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>
              <button type="button" onClick={() => props.onSort('onHiredByUserFirstName')}>
                On-Hired By {props.sort.field === 'onHiredByUserFirstName' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>
              <button type="button" onClick={() => props.onSort('validUntilDate')}>
                Valid Until {props.sort.field === 'validUntilDate' ? `(${props.sort.direction})` : ''}
              </button>
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.map((containerOnHireStatus) => {
            const terminal = containerOnHireStatus.terminal || {};
            const onHiredByUser = containerOnHireStatus.createdByUser || {};
            const { containerNumber, updatedAt: onHireDate, validUntilDate, terminalUuid } = containerOnHireStatus;
            const { firstName, lastName } = onHiredByUser;
            const formattedName = `${firstName} ${lastName}`;
            const { nickname } = terminal;
            const formattedOnHireDate = formatDate(onHireDate);
            const formattedValidUntilDate = formatDate(validUntilDate);
            return (
              <tr key={`${containerNumber}::${terminalUuid}`}>
                <td>
                  {containerNumber} <CopyButton value={containerNumber} />
                </td>
                <td>{nickname}</td>
                <td>{formattedOnHireDate}</td>
                <td>{formattedName}</td>
                <td>{formattedValidUntilDate}</td>
                <td>
                  <IconButton
                    floating
                    async
                    icon={<TrashIcon />}
                    disabled={isDeleting}
                    onClick={() => {
                      onDelete({
                        containerNumber,
                        terminalUuid
                      });
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;
