import moment from 'moment';
import React, { MouseEvent, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Chassis } from '@client/_blessed/store/entities';
import CheckCircleIcon from '@components/deprecatedTookit/icons/fa/regular/CheckCircleIcon';
import TimesCricleIcon from '@components/deprecatedTookit/icons/fa/regular/TimesCircleIcon';
import Checkbox from '@client/components/Checkbox/component';
import { HandleChassisDeleteChange } from '../../types';

import './styles.css';

interface Props {
  chassisInfo: Chassis;
  handleOnDeleteChange: HandleChassisDeleteChange;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function ChassisListItem({ chassisInfo, handleOnDeleteChange, onClick }: Props) {
  const baseClassName = 'chassisItem';
  const {
    id,
    chassisPoolUuid,
    chassisPoolUuidOverride,
    chassisProviderUuid,
    chassisSupportedSize,
    lastPoolUpdated,
    lastProviderUpdated,
    triaxle,
    extendable,
    inRotation,
    isPoolScraped,
    isProviderScraped
  } = chassisInfo;
  const featureFlags = useFlags();
  const { showChassisProviderOnHub } = featureFlags;

  const [checked, setChecked] = useState(false);

  const handleChange = (value: any) => {
    handleOnDeleteChange(id, value);
    setChecked(value);
  };

  return (
    <>
      <td>
        <button type="button" onClick={onClick}>
          {id}
        </button>
      </td>
      <td>
        <button onClick={onClick} tabIndex={-1} type="button">
          {chassisPoolUuid}
        </button>
      </td>
      <td>
        <button onClick={onClick} tabIndex={-1} type="button">
          {chassisPoolUuidOverride}
        </button>
      </td>
      <td>
        <button onClick={onClick} tabIndex={-1} type="button">
          {chassisProviderUuid}
        </button>
      </td>
      <td className={`${baseClassName}__icon`} key={`${id}-${1}`}>
        <button onClick={onClick} type="button" tabIndex={-1}>
          {triaxle ? (
            <CheckCircleIcon className={`${baseClassName}__check-mark`} />
          ) : (
            <TimesCricleIcon className={`${baseClassName}__x-mark`} />
          )}
        </button>
      </td>
      <td className={`${baseClassName}__icon`} key={`${id}-${2}`}>
        <button onClick={onClick} type="button" tabIndex={-1}>
          {extendable ? (
            <CheckCircleIcon className={`${baseClassName}__check-mark`} />
          ) : (
            <TimesCricleIcon className={`${baseClassName}__x-mark`} />
          )}
        </button>
      </td>
      <td className={`${baseClassName}__icon`} key={`${id}-${3}`}>
        <button onClick={onClick} type="button" tabIndex={-1}>
          {inRotation ? (
            <CheckCircleIcon className={`${baseClassName}__check-mark`} />
          ) : (
            <TimesCricleIcon className={`${baseClassName}__x-mark`} />
          )}
        </button>
      </td>
      <td>
        <button onClick={onClick} tabIndex={-1} type="button">
          {chassisSupportedSize}
        </button>
      </td>
      {showChassisProviderOnHub ? (
        <td className={`${baseClassName}__icon`} key={`${id}-${3}`}>
          <button onClick={onClick} type="button" tabIndex={-1}>
            {isPoolScraped ? (
              <CheckCircleIcon className={`${baseClassName}__check-mark`} />
            ) : (
              <TimesCricleIcon className={`${baseClassName}__x-mark`} />
            )}
          </button>
        </td>
      ) : null}
      {showChassisProviderOnHub ? (
        <td>
          <button onClick={onClick} tabIndex={-1} type="button">
            {lastPoolUpdated ? moment(lastPoolUpdated).format('MM/DD/YYYY') : 'N/A'}
          </button>
        </td>
      ) : null}
      {showChassisProviderOnHub ? (
        <td className={`${baseClassName}__icon`} key={`${id}-${3}`}>
          <button onClick={onClick} type="button" tabIndex={-1}>
            {isProviderScraped ? (
              <CheckCircleIcon className={`${baseClassName}__check-mark`} />
            ) : (
              <TimesCricleIcon className={`${baseClassName}__x-mark`} />
            )}
          </button>
        </td>
      ) : null}
      {showChassisProviderOnHub ? (
        <td>
          <button onClick={onClick} tabIndex={-1} type="button">
            {lastProviderUpdated ? moment(lastProviderUpdated).format('MM/DD/YYYY') : 'N/A'}
          </button>
        </td>
      ) : null}
      <td>
        <Checkbox checked={checked} id={`deleted${id}`} onToggle={handleChange} />
      </td>
    </>
  );
}
