export const chassisLabels = {
  ID: 'Id',
  CHASSIS_POOL_UUID: 'Pool',
  CHASSIS_POOL_UUID_OVERRIDE: 'Pool Override',
  CHASSIS_PROVIDER_UUID: 'Provider',
  TRIAXLE: 'Triaxle',
  EXTENDABLE: 'Extendable',
  IN_ROTATION: 'In rotation',
  SUPPORTED_SIZE: 'Supported size',
  IS_POOL_SCRAPED: 'Scraper updated pool?',
  LAST_POOL_UPDATED: 'Pool last updated',
  IS_PROVIDER_SCRAPED: 'Scraper updated provider?',
  LAST_PROVIDER_UPDATED: 'Provider last updated'
};

export const chassisAttributes = {
  ID: 'id',
  CHASSIS_POOL_UUID: 'chassisPoolUuid',
  CHASSIS_POOL_UUID_OVERRIDE: 'chassisPoolUuidOverride',
  CHASSIS_PROVIDER_UUID: 'chassisProviderUuid',
  TRIAXLE: 'triaxle',
  EXTENDABLE: 'extendable',
  IN_ROTATION: 'inRotation',
  SUPPORTED_SIZE: 'supportedSize',
  IS_POOL_SCRAPED: 'isPoolScraped',
  LAST_POOL_UPDATED: 'lastPoolUpdated',
  IS_PROVIDER_SCRAPED: 'isProviderScraped',
  LAST_PROVIDER_UPDATED: 'lastProviderUpdated'
};
