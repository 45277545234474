import React, { ReactElement } from 'react';
import { ChassisPoolEntity } from '@client/_blessed/store/entities';
import ChassiAndChassisPoolsTable from '@client/components/ChassisAndChassisPoolsTable';
import TextInput from '@client/components/TextInput/component';
import { chassisPoolsLabel } from '../../consts/index';
import ChassisPoolListItem from '../ChassisPoolListItem/component';
import { HandleChassisPoolDelete } from '../../types';

import './styles.css';

interface Props {
  chassisPools: ChassisPoolEntity[];
  handleOnDeleteChange: HandleChassisPoolDelete;
  setChassisPoolUuidForEdit: (uuid: string) => void;
  chassisPoolUuidForEdit: string;
  setSearchQuery: (query: string) => void;
  searchQuery: string;
}

function TableHeaders({ tableHeaders }: { tableHeaders: string[] }) {
  return (
    <tr>
      {tableHeaders.map((tableHeader) => (
        <th key={tableHeader}>{tableHeader}</th>
      ))}
      <th>Mark For Delete</th>
    </tr>
  );
}

function ChassisPoolsTable({
  chassisPools,
  handleOnDeleteChange,
  setChassisPoolUuidForEdit,
  chassisPoolUuidForEdit,
  searchQuery,
  setSearchQuery
}: Props): ReactElement {
  const tableHeaders = Object.values(chassisPoolsLabel);
  const baseClassName = 'chassisPoolsTable';

  return (
    <section className={baseClassName}>
      <h2 className="chassisPoolsManagement__title">Chassis Pools</h2>
      <div className={`${baseClassName}__chassis-search`}>
        <TextInput
          className="chassisManagementForm__text-input"
          name="chassisPoolsSearch"
          value={searchQuery}
          onChange={(value) => setSearchQuery(value)}
          required
          placeholder="DZY, POP"
          label="Search chassis pool"
        />
      </div>
      <ChassiAndChassisPoolsTable tableHeaders={<TableHeaders tableHeaders={tableHeaders} />}>
        <>
          {chassisPools.map((chassisPoolInfo) => (
            <tr
              key={chassisPoolInfo.uuid}
              className={chassisPoolInfo.uuid === chassisPoolUuidForEdit ? `${baseClassName}__active` : ''}
            >
              <ChassisPoolListItem
                chassisPoolInfo={chassisPoolInfo}
                handleOnDeleteChange={handleOnDeleteChange}
                onClick={() => setChassisPoolUuidForEdit(chassisPoolInfo.uuid)}
              />
            </tr>
          ))}
        </>
      </ChassiAndChassisPoolsTable>
    </section>
  );
}

export default React.memo(ChassisPoolsTable);
