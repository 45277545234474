// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DualTransFinderFilters {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
}

  .DualTransFinderFilters__tags {
    display: flex;
    flex-wrap: wrap;
  }

  .DualTransFinderFilters__tags > * {
      margin: 10px 5px;
    }

  .DualTransFinderFilters__inputs {
    display: flex;
  }

  .DualTransFinderFilters__inputs > * {
      margin-left: 10px;
    }

  .DualTransFinderFilters__inputs > *:first-child {
        margin-left: 0;
      }

  .DualTransFinderFilters__footer {
    margin-left: 20px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/DualTransFinder/features/DualTransFinderFilters/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,uBAAuB;AA0BzB;;EAxBE;IACE,aAAa;IACb,eAAe;EAKjB;;EAHE;MACE,gBAAgB;IAClB;;EAGF;IACE,aAAa;EASf;;EAPE;MACE,iBAAiB;IAKnB;;EAHE;QACE,cAAc;MAChB;;EAIJ;IACE,iBAAiB;EACnB","sourcesContent":[".DualTransFinderFilters {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  justify-content: center;\n\n  &__tags {\n    display: flex;\n    flex-wrap: wrap;\n\n    & > * {\n      margin: 10px 5px;\n    }\n  }\n\n  &__inputs {\n    display: flex;\n\n    & > * {\n      margin-left: 10px;\n\n      &:first-child {\n        margin-left: 0;\n      }\n    }\n  }\n\n  &__footer {\n    margin-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
