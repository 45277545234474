import { raiseToast } from '@client/components/Toaster';
import request from '@client/utils/request';
import { api } from '@client/utils/url';
import { createAsyncThunk } from '@reduxjs/toolkit';
import SystemToast from '@client/components/SystemToast';
import { sliceId } from './constants';

export const loadAvailableOutgateJobsAction = createAsyncThunk(
  `${sliceId}/loadAvailableOutgateJobs`,
  async (_, thunkAPI) => {
    try {
      const url = api(`/admin/available-outgate-jobs`);
      const resp = await request(url);
      return resp;
    } catch (err) {
      raiseToast(<SystemToast type={SystemToast.Type.ERROR} message="Unable to retrieve available outgate jobs" />);
      return thunkAPI.rejectWithValue(err);
    }
  }
);
