import { ContainerOnHireStatus } from '../types';
import './style.css';

export interface DataTableProps {
  data: ContainerOnHireStatus[];
}

function DataTable(props: DataTableProps) {
  const { data } = props;
  if (data.length === 0) return null;
  return (
    <div className="data-table-wrapper">
      <table className="table">
        <thead>
          <tr>
            <th>Container</th>
            <th>Terminal</th>
            <th>On-Hired Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((containerOnHireStatus) => {
            const { containerNumber, terminal, updatedAt: onHireDate } = containerOnHireStatus;
            const { nickname } = terminal;
            return (
              <tr key={containerNumber}>
                <td>{containerNumber}</td>
                <td>{nickname}</td>
                <td>{onHireDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;
