// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassisItem__check-mark {
    color: green;
  }
  .chassisItem__x-mark {
    color: red;
  }
  .chassisItem__x-mark,
  .chassisItem__check-mark {
    max-width: 1.8rem;
    margin: auto;
  }
  .chassisItem__icon {
    text-align: center;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/ChassisManagement/features/ChassisListItem/styles.css"],"names":[],"mappings":"AACE;IACE,YAAY;EACd;EAEA;IACE,UAAU;EACZ;EAEA;;IAEE,iBAAiB;IACjB,YAAY;EACd;EAEA;IACE,kBAAkB;EACpB","sourcesContent":[".chassisItem {\n  &__check-mark {\n    color: green;\n  }\n\n  &__x-mark {\n    color: red;\n  }\n\n  &__x-mark,\n  &__check-mark {\n    max-width: 1.8rem;\n    margin: auto;\n  }\n\n  &__icon {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
