// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassisTable {
  display: flex;
  flex-direction: column;
}

  .chassisTable__active {
    box-shadow: 0 9px 4px -6px grey;
  }

  .chassisTable__active td.highlight div {
      transform: scaleX(3);
    }

  .chassisTable__active td {
      cursor: pointer;
      background-color: var(--color-green-2);
      border-top: 1px solid grey;
      border-bottom: 1px solid grey;
    }

  .chassisTable__chassis-search {
    position: relative;
    margin-top: -7px;
    margin-bottom: 25px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/ChassisManagement/features/ChassisTable/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AAsBxB;;EApBE;IACE,+BAA+B;EAYjC;;EAVE;MACE,oBAAoB;IACtB;;EAEA;MACE,eAAe;MACf,sCAAsC;MACtC,0BAA0B;MAC1B,6BAA6B;IAC/B;;EAGF;IACE,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":[".chassisTable {\n  display: flex;\n  flex-direction: column;\n\n  &__active {\n    box-shadow: 0 9px 4px -6px grey;\n\n    td.highlight div {\n      transform: scaleX(3);\n    }\n\n    td {\n      cursor: pointer;\n      background-color: var(--color-green-2);\n      border-top: 1px solid grey;\n      border-bottom: 1px solid grey;\n    }\n  }\n\n  &__chassis-search {\n    position: relative;\n    margin-top: -7px;\n    margin-bottom: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
