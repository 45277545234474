import React, { SyntheticEvent, useEffect, useState } from 'react';
import Button from '@client/components/Button/component';
import {
  ChassisPoolEntity,
  upsertChassisPool,
  sendFailureNotification
} from '@client/_blessed/store/entities/chassisPools';
import TextInput from '@client/components/TextInput/component';
import { isEmpty } from 'lodash';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { chassisPools, chassisPoolsLabel } from '../../consts/index';

import './styles.css';

interface Props {
  chassisPoolInfo?: ChassisPoolEntity | undefined;
  btnLabel: string;
}

const initialState = {
  badgeColor: null,
  badgeName: null,
  abbreviation: '',
  name: null,
  description: null
} as ChassisPoolEntity;

function hasSpecialCharacter(str: string): boolean {
  const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  return format.test(str);
}

export default function ChassisPoolsForm({ chassisPoolInfo, btnLabel }: Props) {
  const [newChassisPoolsForm, setForm] = useState(initialState);

  useEffect(() => {
    if (!isEmpty(chassisPoolInfo) && chassisPoolInfo) {
      setForm(chassisPoolInfo);
    }
  }, [chassisPoolInfo]);

  const baseClassName = 'chassisPoolManagementForm';
  const dispatch = useAppDispatch();

  const handleInputChange = (value: string, dataProps: any) => {
    const { name } = dataProps;

    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (hasSpecialCharacter(newChassisPoolsForm.abbreviation)) {
      return sendFailureNotification('Abbreviation cannot have special characters');
    }
    if (newChassisPoolsForm.badgeName && hasSpecialCharacter(newChassisPoolsForm.badgeName)) {
      return sendFailureNotification('Badge name cannot have special characters');
    }
    // Using any to access payload property;
    const { payload } = await dispatch(upsertChassisPool([newChassisPoolsForm])).unwrap();
    if (payload?.length > 0) {
      setForm(initialState);
    }
  };

  const { name, badgeColor, badgeName, abbreviation, description } = newChassisPoolsForm;

  return (
    <div className={baseClassName}>
      <form className={`${baseClassName}__form`} onSubmit={handleSubmit}>
        <TextInput
          name={chassisPools.ABBREVIATION}
          value={abbreviation}
          label={chassisPoolsLabel.ABBREVIATION}
          onChange={handleInputChange}
          maxLength={15}
          required
        />

        <TextInput
          name={chassisPools.BADGE_NAME}
          onChange={handleInputChange}
          value={badgeName}
          label={chassisPoolsLabel.BADGE_NAME}
          maxLength={15}
        />

        <TextInput
          name={chassisPools.BADGE_COLOR}
          onChange={handleInputChange}
          value={badgeColor}
          label={chassisPoolsLabel.BADGE_COLOR}
          maxLength={15}
        />
        <TextInput
          name={chassisPools.NAME}
          onChange={handleInputChange}
          value={name}
          label={chassisPoolsLabel.NAME}
          required
        />
        <TextInput
          className={`${baseClassName}__textarea`}
          isArea
          onChange={handleInputChange}
          value={description}
          name={chassisPools.DESCRIPTION}
          placeholder="Chassis Pool Description"
        />
        <Button disabled={false} theme="2" label={btnLabel} loading={false} type="button" submit />
      </form>
    </div>
  );
}
