// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassisPoolManagementForm {
  display: flex;
  flex-flow: column;
  row-gap: 4px;
}

  .chassisPoolManagementForm__form {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    padding: 25px;
    border: 1px solid var(--color-grayscale-5);
  }

  .chassisPoolManagementForm__textarea {
    height: 75px;
    max-height: 214px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/ChassisPoolsManagement/features/Form/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,YAAY;AAcd;;EAZE;IACE,aAAa;IACb,eAAe;IACf,aAAa;IACb,aAAa;IACb,0CAA0C;EAC5C;;EAEA;IACE,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":[".chassisPoolManagementForm {\n  display: flex;\n  flex-flow: column;\n  row-gap: 4px;\n\n  &__form {\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 10px;\n    padding: 25px;\n    border: 1px solid var(--color-grayscale-5);\n  }\n\n  &__textarea {\n    height: 75px;\n    max-height: 214px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
