import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { debounce, isEmpty } from 'lodash';
import { fetchChassis, deleteBulkChassis, selectChassisByTermFactory } from '@client/_blessed/store/entities/chassis';
import { selectAllContainerSizes, fetchContainerSizes } from '@client/_blessed/store/entities/containerSizes';
import {
  selectAllChassisPools,
  fetchChassisPools,
  selectChassisPoolsById
} from '@client/_blessed/store/entities/chassisPools';
import {
  selectAllChassisProviders,
  fetchChassisProviders,
  selectChassisProvidersById
} from '@client/_blessed/store/entities/chassisProviders';
import Button from '@client/components/Button/component';
import ChassisAndChassisPoolDelete from '@client/components/ChassisAndChassisPoolDelete/component';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { convertChassisPoolAndSizesAndProvidersToName } from './store/helper';
import ChassisTable from './features/ChassisTable/component';
import ChassisForm from './features/Form/component';
import EditableChassis from './features/EditableChassis/component';
import './styles.css';
import { HandleChassisDeleteChange } from './types';

export interface ChassisForDelete {
  id: boolean;
}

function ChassisManagement() {
  const baseClassName = 'chassisManagement';

  const [chassisForDelete, setChassisForDelete] = useState({} as ChassisForDelete);
  const [chassisIdForEdit, setChassisIdForEdit] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useAppDispatch();
  const chassisPools = useSelector(selectAllChassisPools);
  const chassisProviders = useSelector(selectAllChassisProviders);
  const chassisSizes = useSelector(selectAllContainerSizes);
  const chassisPoolsById = useSelector(selectChassisPoolsById);
  const chassisProvidersById = useSelector(selectChassisProvidersById);
  const chassisBySearchQuery = useSelector(selectChassisByTermFactory(searchQuery));
  const [openDialouge, setDialouge] = useState(false);

  useEffect(() => {
    dispatch(fetchChassis());
    dispatch(fetchContainerSizes());
    dispatch(fetchChassisPools());
    dispatch(fetchChassisProviders());
  }, []);

  const allChassisWithPoolAndProviderName = convertChassisPoolAndSizesAndProvidersToName(
    chassisBySearchQuery,
    chassisPools,
    chassisProviders,
    chassisSizes
  );

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  const debouncedSearchQuery = useCallback(debounce(handleSearchQuery, 1000), []);

  const handleOnDeleteChange: HandleChassisDeleteChange = (id, isSetForDelete) => {
    const map = {} as ChassisForDelete;
    const previousChassisForDelete = chassisForDelete;
    map[id as keyof ChassisForDelete] = isSetForDelete;
    setChassisForDelete({ ...previousChassisForDelete, ...map });
  };

  const handleBulkDelete = async () => {
    const chassisIds = Object.keys(chassisForDelete).filter((uuid) => chassisForDelete[uuid as keyof ChassisForDelete]);

    await dispatch(deleteBulkChassis(chassisIds));
    setChassisForDelete({} as ChassisForDelete);
    setDialouge(false);
  };

  const handleDeleteDialouge = () => {
    if (openDialouge) {
      setDialouge(false);
    } else {
      setDialouge(true);
    }
  };

  return (
    <>
      <Helmet title="Chassis Management" />
      <div className={baseClassName}>
        <div className={`${baseClassName}__content`}>
          <section id="newChassis">
            <h1 className={`${baseClassName}__title`}>Create new chassis</h1>
            <ChassisForm
              btnLabel="Create Chassis"
              chassisPoolsById={chassisPoolsById}
              chassisProvidersById={chassisProvidersById}
              chassisSizes={chassisSizes}
              chassisPools={chassisPools}
              chassisProviders={chassisProviders}
            />
          </section>
          <ChassisTable
            chassis={allChassisWithPoolAndProviderName}
            handleOnDeleteChange={handleOnDeleteChange}
            chassisId={chassisIdForEdit}
            setChassisIdForEdit={setChassisIdForEdit}
            setSearchQuery={debouncedSearchQuery}
            searchQuery={searchQuery}
          />
          <EditableChassis
            chassisId={chassisIdForEdit}
            setChassisIdForEdit={setChassisIdForEdit}
            chassisPoolsById={chassisPoolsById}
            chassisProvidersById={chassisProvidersById}
            chassisSizes={chassisSizes}
            chassisPools={chassisPools}
            chassisProviders={chassisProviders}
          />

          {!isEmpty(chassisForDelete) && Object.values(chassisForDelete).some((item) => item) && (
            <Button
              disabled={false}
              theme="2"
              label="Confirm Chassis Deletion"
              loading={false}
              className={`${baseClassName}__bulk-delete-btn`}
              onClick={handleDeleteDialouge}
            />
          )}

          <ChassisAndChassisPoolDelete
            open={openDialouge}
            itemsForDelete={Object.keys(chassisForDelete).filter(
              // show only chassis ready for delete
              (key: string) => chassisForDelete[key as keyof ChassisForDelete]
            )}
            confirmDelete={handleBulkDelete}
            cancelDelete={handleDeleteDialouge}
            message="You are deleting the following chassis."
          />
        </div>
      </div>
    </>
  );
}

export default ChassisManagement;
