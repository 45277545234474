import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Department from '@client/_blessed/store/entities/departments/model';
import { useSelector } from 'react-redux';
import { selectTerminals } from '@client/_blessed/store/entities/departments';
import BulkOrderCancellationForm from './features/Form/component';
import DataTable from './features/DataTable/component';
import ErrorTable from './features/ErrorTable/component';
import './style.css';
import { TerminalOption } from './features/types';

const pageClassName = 'container-on-hire-status';

function Container() {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);

  const terminals = useSelector(selectTerminals) || [];
  const terminalOptions: TerminalOption[] = terminals.map((terminal: Department) => ({ uuid: terminal.uuid, key: terminal.uuid, nickname: terminal.nickname || terminal.name }));

  return (
    <>
      <Helmet title="New On-Hire" />
      <div className={pageClassName}>
        <h1 className={`${pageClassName}__h1`}>Terminal On Hire Status</h1>
        <BulkOrderCancellationForm
          terminalOptions={terminalOptions}
          setIsFetching={setIsFetching}
          setErrors={setErrors}
          setData={setData}
          isFetching={isFetching}
        />
        {isFetching ? 'Loading...' : null}
        <DataTable data={data} />
        <ErrorTable errors={errors} />
      </div>
    </>
  );
}

export default Container;
