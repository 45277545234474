// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassisPoolsManagement {
  display: flex;
  flex-direction: column;
  position: relative;
}

  .chassisPoolsManagement__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1538px;
  }

  @media only screen and (width <= 1176px) {

  .chassisPoolsManagement__content {
      grid-template-columns: 1fr;
      row-gap: 25px;
  }
    }

  .chassisPoolsManagement__content {

    column-gap: 15px;
    padding: 25px;
}

  .chassisPoolsManagement__title {
    padding-bottom: 30px;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
  }

  .chassisPoolsManagement__bulk-delete-btn {
    position: absolute;
    right: 0;
    margin-right: 25px;
  }

  @media only screen and (width <= 1176px) {

  .chassisPoolsManagement__bulk-delete-btn {
      top: 32vh
  }
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/ChassisPoolsManagement/styles.css","<no source>"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AAgCpB;;EA9BE;IACE,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;EASnB;;EAPE;;EALF;MAMI,0BAA0B;MAC1B,aAAa;EAKjB;IAJE;;ECbJ;;IDeI,gBAAgB;IAChB;CChBJ;;EDmBE;IACE,oBAAoB;IACpB,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,kBAAkB;EAKpB;;EAHE;;EALF;MAMI;EAEJ;IADE","sourcesContent":[".chassisPoolsManagement {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  &__content {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    max-width: 1538px;\n\n    @media only screen and (width <= 1176px) {\n      grid-template-columns: 1fr;\n      row-gap: 25px;\n    }\n\n    column-gap: 15px;\n    padding: 25px;\n  }\n\n  &__title {\n    padding-bottom: 30px;\n    font-size: 1.2rem;\n    font-weight: 600;\n    margin: 0;\n  }\n\n  &__bulk-delete-btn {\n    position: absolute;\n    right: 0;\n    margin-right: 25px;\n\n    @media only screen and (width <= 1176px) {\n      top: 32vh;\n    }\n  }\n}\n",null],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
