// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorTable .table-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .ErrorTable .table {
    border: 1px solid var(--color-grayscale-5);
    width: 100%;
    text-align: left;
  }
  .ErrorTable tr {
    border: 1px solid #ccc;
  }
  .ErrorTable th {
    font-weight: 500;
    background: var(--color-grayscale-2);
  }
  .ErrorTable th,
  .ErrorTable td {
    padding: 15px 20px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/OnHireEntry/features/ErrorTable/style.css"],"names":[],"mappings":"AACE;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,gBAAgB;EAClB;EAEA;IACE,0CAA0C;IAC1C,WAAW;IACX,gBAAgB;EAClB;EAEA;IACE,sBAAsB;EACxB;EAEA;IACE,gBAAgB;IAChB,oCAAoC;EACtC;EAEA;;IAEE,kBAAkB;EACpB","sourcesContent":[".ErrorTable {\n  .table-title {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    font-size: 16px;\n    font-weight: 500;\n  }\n\n  .table {\n    border: 1px solid var(--color-grayscale-5);\n    width: 100%;\n    text-align: left;\n  }\n\n  tr {\n    border: 1px solid #ccc;\n  }\n\n  th {\n    font-weight: 500;\n    background: var(--color-grayscale-2);\n  }\n\n  th,\n  td {\n    padding: 15px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
