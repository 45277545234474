// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chassisManagementForm {
  display: flex;
  flex-flow: column;
  row-gap: 4px;
}

  .chassisManagementForm__form {
    display: flex;
    flex-wrap: wrap;
    row-gap: 25px;
    padding: 25px;
    border: 1px solid var(--color-grayscale-5);
  }

  .chassisManagementForm__checkbox-group {
    display: flex;
    width: 100%;
    column-gap: 15px;
    flex-wrap: wrap;
  }

  .chassisManagementForm__textarea {
    height: 75px;
    max-height: 214px;
  }

  .chassisManagementForm__text-input-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .chassisManagementForm__text-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    min-width: 175px;
  }

  .chassisManagementForm__chassis-size-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .chassisManagementForm__chassis-size {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
  }

  .chassisManagementForm__chassis-size-checkbox {
    justify-content: unset;
  }

  .chassisManagementForm__label {
    color: var(--color-grayscale-6);
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/ChassisManagement/features/Form/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,YAAY;AA0Dd;;EAxDE;IACE,aAAa;IACb,eAAe;IACf,aAAa;IACb,aAAa;IACb,0CAA0C;EAC5C;;EAEA;IACE,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,2BAA2B;IAC3B,qCAAqC;EACvC;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".chassisManagementForm {\n  display: flex;\n  flex-flow: column;\n  row-gap: 4px;\n\n  &__form {\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 25px;\n    padding: 25px;\n    border: 1px solid var(--color-grayscale-5);\n  }\n\n  &__checkbox-group {\n    display: flex;\n    width: 100%;\n    column-gap: 15px;\n    flex-wrap: wrap;\n  }\n\n  &__textarea {\n    height: 75px;\n    max-height: 214px;\n  }\n\n  &__text-input-wrapper {\n    display: flex;\n    flex-direction: column;\n    row-gap: 15px;\n  }\n\n  &__text-input {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    max-width: 250px;\n    min-width: 175px;\n  }\n\n  &__chassis-size-wrapper {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n\n  &__chassis-size {\n    display: grid;\n    grid-template-rows: 1fr 1fr;\n    grid-template-columns: repeat(2, 1fr);\n  }\n\n  &__chassis-size-checkbox {\n    justify-content: unset;\n  }\n\n  &__label {\n    color: var(--color-grayscale-6);\n    font-size: 12px;\n    line-height: 24px;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
