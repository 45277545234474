import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';
import store from '../../store';
import {
  terminalSelector,
  legStatusSelector,
  shipperSelector,
  carrierSelector,
  containerSizeSelector
} from '../../store/selectors';

function Container() {
  const dispatch = useAppDispatch();
  const terminals = useSelector(terminalSelector);
  const legStatuses = useSelector(legStatusSelector);
  const shippers = useSelector(shipperSelector);
  const carriers = useSelector(carrierSelector);
  const containerSizes = useSelector(containerSizeSelector);

  const handleFilter = (value: any) => {
    dispatch(store.actions.filter(value));
  };

  return (
    <Component
      terminals={terminals}
      legStatuses={legStatuses}
      shippers={shippers}
      carriers={carriers}
      containerSizes={containerSizes}
      onFilterChange={handleFilter}
    />
  );
}

export default React.memo(Container);
