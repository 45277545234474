import React, { useState } from 'react';
import moment from 'moment';
import './style.css';
import { DualIngateSmartMatch } from '@client/_blessed/models';
import { ContainerOnStatusEntity, GeneratorLeg } from '@client/_blessed/store/entities';
import { TIME_ZONES } from '@drayalliance/types';
import DualInMatchesSidebar from '../ReactiveDualTrans/features/DualInMatchesSidebar';
import DualTransFinderFilters from './features/DualTransFinderFilters';
import { AvailableOutgateJob } from './store/models';

function formatAppointment(appt: string, timezone?: TIME_ZONES) {
  return appt
    ? moment(appt)
        .tz(timezone || TIME_ZONES.AMERICA_LOS_ANGELES)
        .format('MM/DD HH:mm')
    : '';
}

interface Props {
  data: AvailableOutgateJob[];
  tableSort: { column: string; direction: string };
  // eslint-disable-next-line no-unused-vars
  onTableSort: (_: any) => void;
  // eslint-disable-next-line no-unused-vars
  onLoadMatches: (params: { legID: string; legNumber: number }) => void;
  onResetMatches: () => void;
  matches: DualIngateSmartMatch[];
  matchesLoading: boolean;
  generatorMatches: GeneratorLeg[];
  driversById: any;
  departmentsById: any;
  matchSort: any;
  // eslint-disable-next-line no-unused-vars
  onMatchSort: (value: any) => void;
  locationOptions: { value: string }[];
  useNewBundles: boolean;
  onHireStatusesByContainer: Record<string, ContainerOnStatusEntity>;
}

function DualTransFinder(props: Props) {
  const className = 'DualTransFinder';
  const [selectedLeg, selectLeg] = useState<any | null>(null);
  const [driver, selectDriver] = useState<any | null>(null);
  const sortDirection = props.tableSort.direction;
  const sortedColumn = props.tableSort.column;
  const setSelectedLeg = (item: any) => {
    const newSelected: any = {
      legUuid: item.legUuid,
      legNumber: item.legNumber,
      legStatus: item.legStatus,
      legType: item.legType,
      orderImportExport: item.orderImportExport,
      orderDeliveryType: item.orderDeliveryType,
      containerNumber: item.containerNumber,
      steamShippingLineAccountUuid: item.sslUuid,
      containerSize: item.size,
      firstStopAppointmentStart: item.firstStopAppointmentStart,
      lastStopAppointmentStart: item.lastStopAppointmentStart,
      firstStopDepartmentUuid: item.firstStopDepartmentUuid,
      lastFreeDay: item.lastFreeDay,
      perDiemDueDate: item.perDiemDueDate,
      jobStops: item.Terminal
    };
    selectDriver(item.driver ? props.driversById[item.driver] : null);
    selectLeg(newSelected);
    props.onLoadMatches({ legID: item.legUuid, legNumber: item.legNumber });
  };
  const handleCloseDrawer = (e: any) => {
    props.onResetMatches();
    if (e) {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    selectLeg(null);
  };
  const toggleColumn = (column: keyof AvailableOutgateJob) => {
    if (props.onTableSort) {
      props.onTableSort({
        column,
        direction: sortDirection.toUpperCase() === 'ASC' ? 'DESC' : 'ASC'
      });
    }
  };

  return (
    <div className={className}>
      <div className={`${className}-title`}>Dual Trans Finder (Load {'->'} Empty)</div>
      <DualTransFinderFilters />
      <table className={`${className}-table`}>
        <thead>
          <tr>
            <th onClick={() => toggleColumn('containerNumber')}>
              Container {sortedColumn === 'containerNumber' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('legNumber')}>
              Leg Number {sortedColumn === 'legNumber' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('Shipper')}>
              Shipper {sortedColumn === 'Shipper' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('Terminal')}>
              Terminal {sortedColumn === 'Terminal' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('size')}>Size {sortedColumn === 'size' ? `(${sortDirection})` : ''}</th>
            <th onClick={() => toggleColumn('AppointmentTime')}>
              Appointment Time {sortedColumn === 'AppointmentTime' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('LFD')}>LFD {sortedColumn === 'LFD' ? `(${sortDirection})` : ''}</th>
            <th onClick={() => toggleColumn('nextStop')}>
              Next Stop {sortedColumn === 'nextStop' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('Carrier')}>
              Carrier {sortedColumn === 'Carrier' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('legStatus')}>
              Leg Status {sortedColumn === 'legStatus' ? `(${sortDirection})` : ''}
            </th>
            <th onClick={() => toggleColumn('Dual?')}>Dual? {sortedColumn === 'Dual?' ? `(${sortDirection})` : ''}</th>
            <th onClick={() => toggleColumn('SSL')}>SSL {sortedColumn === 'SSL' ? `(${sortDirection})` : ''}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item) => {
            const clickHandler = () => setSelectedLeg(item);

            return (
              <tr key={item.legNumber} onClick={clickHandler}>
                <td>{item.containerNumber}</td>
                <td>{item.legNumber}</td>
                <td>{item.Shipper}</td>
                <td>{item.Terminal}</td>
                <td>{item.size}</td>
                <td>{formatAppointment(item.AppointmentTime)}</td>
                <td>{item.LFD}</td>
                <td>{item.nextStop}</td>
                <td>{item.Carrier}</td>
                <td>{item.legStatus}</td>
                <td className={`${className}-table-dual`}>{item['Dual?']}</td>
                <td>{item.SSL}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <DualInMatchesSidebar
        selectedLeg={selectedLeg}
        onCloseDrawer={handleCloseDrawer}
        departmentsById={props.departmentsById}
        driver={driver}
        matches={props.matches}
        generatorMatches={props.generatorMatches}
        matchesLoading={props.matchesLoading}
        matchSort={props.matchSort}
        onMatchSort={props.onMatchSort}
        locationOptions={props.locationOptions}
        useNewBundles={props.useNewBundles}
        onHireStatusesByContainer={props.onHireStatusesByContainer}
      />
    </div>
  );
}
export default DualTransFinder;
