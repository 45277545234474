import React, { useState } from 'react';
import moment from 'moment';
import Button from '@client/components/Button';
import {
  ContainerSize,
  selectEmptySupportedMapByTerminalSslAndSizeUuid,
  selectEmptySupportedMapByTerminalUuid
} from '@client/_blessed/store/entities';
import { useSelector } from 'react-redux';
import Department from '@client/_blessed/store/entities/departments/model';
import EmptyReturnAcceptanceFilters from './features/EmptyReturnAcceptanceFilters/container';

import './style.css';

interface EmptyReturnSupported {
  sslUuid: string;
  sizeUuid: string;
  terminalUuid: string;
  returnDate: string;
  updatedAt?: Date;
  shift: 0 | 1;
  accepting: 'yes' | 'no' | 'dual';
}

interface EmptyReturnAcceptanceProps {
  containerSizes: ContainerSize[];
  terminals: Department[];
  shippingLines: Department[];
  handleShowAllRows: () => void;
  showAllRows: boolean;
}

const className = (...classes: string[]) =>
  classes.length === 0
    ? 'EmptyReturnAcceptance'
    : [...(classes || []).map((el) => `EmptyReturnAcceptance__${el}`)].join(' ');

const getRecordGenerationDate = (
  terminalUuid: string,
  emptySupportMapByTerminal: Record<string, EmptyReturnSupported[]>
) => {
  const emptyReturnSupported = emptySupportMapByTerminal[terminalUuid];
  const upsertDate = emptyReturnSupported?.find((el: EmptyReturnSupported) => el.terminalUuid === terminalUuid)
    ?.updatedAt;
  return `Last scrape run: ${moment(upsertDate).format('YYYY-MM-DD h:mm a')}`;
};

function EmptyReturnAcceptance(props: EmptyReturnAcceptanceProps) {
  const today = moment().format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
  const [selectedDate, selectDate] = useState(today);

  const emptySupportedMapByTerminalSslAndSize = useSelector(selectEmptySupportedMapByTerminalSslAndSizeUuid);
  const emptySupportMapByTerminal = useSelector(selectEmptySupportedMapByTerminalUuid);

  return (
    <div className={className()}>
      <a target="_blank" href="https://track.portoptimizer.com/terminal/empties" rel="noreferrer">
        Port Optimizer
      </a>
      <br />
      <a
        target="_blank"
        href="https://docs.google.com/spreadsheets/d/1tnKzyzgYSjnwwlZjuEH4CNW7UpkYeCahxG37So3ZRCo/edit#gid=0"
        rel="noreferrer"
      >
        Overrides Google Sheet
      </a>
      <div className={className('dateFilters')}>
        <Button
          className={className(`${selectedDate === today ? 'dateFilters-active' : 'dateFilters-inactive'}`)}
          onClick={() => selectDate(today)}
          label={today}
        />
        <Button
          className={className(`${selectedDate === tomorrow ? 'dateFilters-active' : 'dateFilters-inactive'}`)}
          onClick={() => selectDate(tomorrow)}
          label={tomorrow}
        />
      </div>

      <Button
        className={className('show-hide-button')}
        onClick={props.handleShowAllRows}
        label={props.showAllRows ? 'Showing All Rows' : 'Hiding Non-Accepting Rows'}
        theme="2"
      />

      <EmptyReturnAcceptanceFilters />

      {props.terminals.map((terminal) => (
        <React.Fragment key={terminal.uuid}>
          <h2>
            {terminal.nickname}
            <i className={className('last-upsert-date')}>
              {getRecordGenerationDate(terminal.uuid, emptySupportMapByTerminal)}
            </i>
          </h2>
          <table className={className('table')}>
            <thead>
              <tr>
                <th className={className('table_terminal_th')}>Shipping Line</th>
                <th>Terminal Shift</th>
                {props.containerSizes.map((containerSize) => (
                  <th key={containerSize.uuid}>
                    {containerSize.length}
                    {containerSize.type.toUpperCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.shippingLines.map((ssl) => {
                const acceptingContainers = props.containerSizes.filter((size) => {
                  const key = `${terminal.uuid}::${ssl.uuid}::${size.uuid}`;
                  const emptyReturnAccepted = emptySupportedMapByTerminalSslAndSize[key];
                  const emptyReturnAcceptanceBySelectedDate = emptyReturnAccepted?.filter((e: EmptyReturnSupported) => {
                    const matchingDates = e.returnDate === selectedDate;
                    return matchingDates && e.accepting !== 'no';
                  });
                  return emptyReturnAcceptanceBySelectedDate?.length > 0;
                });

                const shouldDisplayRow = acceptingContainers.length > 0;

                if (!shouldDisplayRow && !props.showAllRows) {
                  return null;
                }

                return (
                  <React.Fragment key={ssl.uuid}>
                    <tr>
                      <td rowSpan={2}>{ssl.nickname}</td>
                      <td>First</td>
                      {props.containerSizes.map((size) => {
                        const key = `${terminal.uuid}::${ssl.uuid}::${size.uuid}`;
                        const emptyReturnAccepted = emptySupportedMapByTerminalSslAndSize[key];
                        const accepting =
                          emptyReturnAccepted?.find(
                            (e: EmptyReturnSupported) => e.returnDate === selectedDate && e.shift === 0
                          )?.accepting || 'no';

                        return (
                          <td key={`first${key}`} className={className('cell', `accepting-${accepting}`)}>
                            {accepting}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>Second</td>
                      {props.containerSizes.map((size) => {
                        const key = `${terminal.uuid}::${ssl.uuid}::${size.uuid}`;
                        const emptyReturnAccepted = emptySupportedMapByTerminalSslAndSize[key];
                        const accepting =
                          emptyReturnAccepted?.find(
                            (e: EmptyReturnSupported) => e.returnDate === selectedDate && e.shift === 1
                          )?.accepting || 'no';

                        return (
                          <td key={`second_${key}`} className={className('cell', `accepting-${accepting}`)}>
                            {accepting}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      ))}
    </div>
  );
}

export default EmptyReturnAcceptance;
