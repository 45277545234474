import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { fetchContainerSizes } from '@client/_blessed/store/entities/containerSizes';
import { retrieveEmptyReturnAcceptanceAction } from '@client/_blessed/store/entities/emptyReturnAcceptance';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';
import { filteredSslsForPage, filteredTerminalsForPage, filteredSizesForPage } from './store/selectors';

function Container() {
  const dispatch = useAppDispatch();
  const [showAllRows, setShowAllRows] = useState(false);

  const filteredContainerSizes = useSelector(filteredSizesForPage);
  const filteredTerminals = useSelector(filteredTerminalsForPage);
  const filteredShippingLines = useSelector(filteredSslsForPage);

  const handleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };

  useEffect(() => {
    const promise = dispatch(fetchContainerSizes());

    return () => {
      promise.abort();
    };
  }, []);

  useEffect(() => {
    const promise = dispatch(retrieveEmptyReturnAcceptanceAction({}));

    return () => {
      promise.abort();
    };
  }, []);

  return (
    <>
      <Helmet title="Empty Acceptance" />
      <Component
        terminals={filteredTerminals}
        shippingLines={filteredShippingLines}
        containerSizes={filteredContainerSizes}
        handleShowAllRows={handleShowAllRows}
        showAllRows={showAllRows}
      />
    </>
  );
}

export default React.memo(Container);
