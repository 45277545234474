import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Chassis } from '@client/_blessed/store/entities';
import ChassiAndChassisPoolsTable from '@client/components/ChassisAndChassisPoolsTable';
import TextInput from '@client/components/TextInput/component';
import ChassisListItem from '../ChassisListItem/component';
import { HandleChassisDeleteChange } from '../../types';
import { chassisLabels } from '../../consts';

import './styles.css';

interface Props {
  chassis: Chassis[];
  handleOnDeleteChange: HandleChassisDeleteChange;
  chassisId: string;
  setChassisIdForEdit: (id: string) => void;
  setSearchQuery: (query: string) => void;
  searchQuery: string;
}

function TableHeaders({ showChassisProviderOnHub }: { showChassisProviderOnHub: boolean }) {
  return (
    <tr>
      <th key={`${chassisLabels.ID}${1}`}>{chassisLabels.ID}</th>
      <th key={`${chassisLabels.CHASSIS_POOL_UUID}${2}`}>{chassisLabels.CHASSIS_POOL_UUID}</th>
      <th key={`${chassisLabels.CHASSIS_POOL_UUID_OVERRIDE}${2}`}>{chassisLabels.CHASSIS_POOL_UUID_OVERRIDE}</th>
      <th key={`${chassisLabels.CHASSIS_PROVIDER_UUID}${3}`}>{chassisLabels.CHASSIS_PROVIDER_UUID}</th>
      <th key={`${chassisLabels.TRIAXLE}${4}`}>{chassisLabels.TRIAXLE}</th>
      <th key={`${chassisLabels.EXTENDABLE}${5}`}>{chassisLabels.EXTENDABLE}</th>
      <th key={`${chassisLabels.IN_ROTATION}${6}`}>{chassisLabels.IN_ROTATION}</th>
      <th key={`${chassisLabels.SUPPORTED_SIZE}${7}`}>{chassisLabels.SUPPORTED_SIZE}</th>
      {showChassisProviderOnHub ? (
        <th key={`${chassisLabels.IS_POOL_SCRAPED}${8}`}>{chassisLabels.IS_POOL_SCRAPED}</th>
      ) : null}
      {showChassisProviderOnHub ? (
        <th key={`${chassisLabels.LAST_POOL_UPDATED}${9}`}>{chassisLabels.LAST_POOL_UPDATED}</th>
      ) : null}
      {showChassisProviderOnHub ? (
        <th key={`${chassisLabels.IS_PROVIDER_SCRAPED}${10}`}>{chassisLabels.IS_PROVIDER_SCRAPED}</th>
      ) : null}
      {showChassisProviderOnHub ? (
        <th key={`${chassisLabels.LAST_PROVIDER_UPDATED}${11}`}>{chassisLabels.LAST_PROVIDER_UPDATED}</th>
      ) : null}
      <th>Mark For Delete</th>
    </tr>
  );
}

function ChassisTable({
  chassis,
  handleOnDeleteChange,
  chassisId,
  setChassisIdForEdit,
  searchQuery,
  setSearchQuery
}: Props) {
  const baseClassName = 'chassisTable';

  const featureFlags = useFlags();
  const { showChassisProviderOnHub } = featureFlags;

  return (
    <section className={baseClassName}>
      <h2 className="chassisManagement__title">Chassis</h2>
      <div className={`${baseClassName}__chassis-search`}>
        <TextInput
          className="chassisManagementForm__text-input"
          name="chassisSearch"
          value={searchQuery}
          onChange={(value) => setSearchQuery(value)}
          required
          placeholder="ABCD12356, EFGH123456"
          label="Search chassis"
        />
      </div>
      <ChassiAndChassisPoolsTable tableHeaders={<TableHeaders showChassisProviderOnHub={showChassisProviderOnHub} />}>
        <>
          {chassis.map((chassisInfo, i) => (
            <tr key={chassisInfo.id} className={chassisInfo.id === chassisId ? `${baseClassName}__active` : ''}>
              <ChassisListItem
                chassisInfo={chassisInfo}
                key={`${chassisInfo.id}-${i + 1}`}
                handleOnDeleteChange={handleOnDeleteChange}
                onClick={() => setChassisIdForEdit(chassisInfo.id)}
              />
            </tr>
          ))}
        </>
      </ChassiAndChassisPoolsTable>
    </section>
  );
}

export default React.memo(ChassisTable);
