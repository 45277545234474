import React, { ReactElement, useState, MouseEvent } from 'react';
import { ChassisPoolEntity } from '@client/_blessed/store/entities';
import Checkbox from '@client/components/Checkbox/component';
import { HandleChassisPoolDelete } from '../../types';

import './styles.css';

interface Props {
  chassisPoolInfo: ChassisPoolEntity;
  handleOnDeleteChange: HandleChassisPoolDelete;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export default function ChassisPoolListItem({ chassisPoolInfo, handleOnDeleteChange, onClick }: Props): ReactElement {
  // remove uuid
  const { uuid, ...chassisPoolsInfoWihtoutUuid } = chassisPoolInfo;
  const chassisPoolsCellInfo = Object.values(chassisPoolsInfoWihtoutUuid);

  const [checked, setChecked] = useState(false);

  const handleChange = (value: any) => {
    handleOnDeleteChange(uuid, value);
    setChecked(value);
  };

  return (
    <>
      {chassisPoolsCellInfo.map((cellInfo, i) => {
        if (!cellInfo)
          return (
            // eslint-disable-next-line react/no-array-index-key
            <td key={i}>
              <button type="button" onClick={onClick}>
                N/A
              </button>
            </td>
          );
        return (
          // eslint-disable-next-line react/no-array-index-key
          <td key={`${i}`}>
            <button type="button" onClick={onClick}>
              {cellInfo}
            </button>
          </td>
        );
      })}
      <td>
        <Checkbox checked={checked} id={`deleted${uuid}`} onToggle={handleChange} />
      </td>
    </>
  );
}
