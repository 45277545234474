import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import {
  terminalFilterOptions,
  selectedTerminalFilterOptions,
  shippingLineFilterOptions,
  selectedSslFilterOptions,
  sizeFilterOptions,
  selectedSizeFilterOptions
} from '../../store/selectors';
import Component from './component';
import store from '../../store';

function Container() {
  const dispatch = useAppDispatch();

  const terminalOptions = useSelector(terminalFilterOptions);
  const selectedTerminalOptions = useSelector(selectedTerminalFilterOptions);
  const sslOptions = useSelector(shippingLineFilterOptions);
  const selectedSslOptions = useSelector(selectedSslFilterOptions);
  const sizeOptions = useSelector(sizeFilterOptions);
  const selectedSizeOptions = useSelector(selectedSizeFilterOptions);

  const setTerminalFilters = (items: { name: string | null; value: string }[]) => {
    dispatch(store.actions.setTerminalFilters(items.map((item) => item.value)));
  };

  const setSslFilters = (items: { name: string | null; value: string }[]) => {
    dispatch(store.actions.setSslFilters(items.map((item) => item.value)));
  };

  const setSizeFilters = (items: { name: string | null; value: string }[]) => {
    dispatch(store.actions.setSizeFilters(items.map((item) => item.value)));
  };

  const clearAllFilters = () => {
    dispatch(store.actions.clearAllFilters());
  };

  return (
    <Component
      terminalOptions={terminalOptions}
      sslOptions={sslOptions}
      sizeOptions={sizeOptions}
      selectedTerminalOptions={selectedTerminalOptions}
      selectedSslOptions={selectedSslOptions}
      selectedSizeOptions={selectedSizeOptions}
      setTerminalFilters={setTerminalFilters}
      setSslFilters={setSslFilters}
      setSizeFilters={setSizeFilters}
      clearAllFilters={clearAllFilters}
    />
  );
}

export default React.memo(Container);
