import { createSelector } from 'reselect';
import { selectAdminSlice } from '../../../store/selectors';
import { AvailableOutgateJob } from './models';
import { sliceId } from './constants';

type Column = keyof AvailableOutgateJob;

const sortBy =
  ({ column, direction }: { column: Column; direction: 'ASC' | 'DESC' }) =>
  (a: AvailableOutgateJob, b: AvailableOutgateJob) => {
    let aVal = a[column] || '';
    let bVal = b[column] || '';

    aVal = typeof aVal === 'string' ? aVal.toUpperCase() : aVal;
    bVal = typeof bVal === 'string' ? bVal.toUpperCase() : bVal;

    if (direction.toUpperCase() === 'ASC') {
      return aVal < bVal ? -1 : 1;
    }
    return aVal > bVal ? -1 : 1;
  };

export const selectDualTransFinderSlice = createSelector(selectAdminSlice, (state) => state[sliceId]);

export const selectAvailableOutgateJobs = (state: any) =>
  selectDualTransFinderSlice(state).availableOutgateJobs.data || [];

export const selectTableSort = (state: any) => selectDualTransFinderSlice(state).tableSort;

export const selectfilters = (state: any) => selectDualTransFinderSlice(state).filters;

export const selectSortedData = createSelector([selectAvailableOutgateJobs, selectTableSort], (jobs, tableSort) =>
  [...jobs].sort(sortBy(tableSort))
);

export const selectTableDataHelper = (containers: any, filters: any) => {
  const result: any[] = [];

  if (containers?.length) {
    containers.forEach((container: any) => {
      let match = true;
      if (filters.terminals?.length && !filters.terminals.find((opt: any) => opt === container.Terminal)) {
        match = false;
      }

      if (filters.legStatuses?.length && !filters.legStatuses.find((opt: any) => opt === container.legStatus)) {
        match = false;
      }

      if (filters.shippers?.length && !filters.shippers.find((opt: any) => opt === container.Shipper)) {
        match = false;
      }

      if (filters.containerSizes?.length && !filters.containerSizes.find((opt: any) => opt === container.size)) {
        match = false;
      }

      if (filters.carriers?.length && !filters.carriers.find((opt: any) => opt === container.Carrier)) {
        match = false;
      }

      if (match) {
        result.push(container);
      }
    });
  }

  return result;
};

export const selectFilteredData = createSelector([selectSortedData, selectfilters], selectTableDataHelper);

// Filter Selectors
export const terminalSelector = createSelector([selectSortedData], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const cpy: any = {};
    containers.forEach((item: any) => {
      if (!cpy[item.Terminal]) {
        // TODO: Verify names / values
        cpy[item.Terminal] = true;

        result.push({
          name: item.Terminal,
          value: item.Terminal
        });
      }
    });
  }

  return result;
});

export const legStatusSelector = createSelector([selectSortedData], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const cpy: any = {};
    containers.forEach((item: any) => {
      if (!cpy[item.legStatus]) {
        // TODO: Verify names / values
        cpy[item.legStatus] = true;

        result.push({
          name: item.legStatus,
          value: item.legStatus
        });
      }
    });
  }

  return result;
});

export const shipperSelector = createSelector([selectSortedData], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const cpy: any = {};
    containers.forEach((item: any) => {
      if (!cpy[item.Shipper]) {
        // TODO: Verify names / values
        cpy[item.Shipper] = true;

        result.push({
          name: item.Shipper,
          value: item.Shipper
        });
      }
    });
  }

  return result;
});

export const carrierSelector = createSelector([selectSortedData], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const cpy: any = {};
    containers.forEach((item: any) => {
      if (item.Carrier && !cpy[item.Carrier]) {
        // TODO: Verify names / values
        cpy[item.Carrier] = true;

        result.push({
          name: item.Carrier,
          value: item.Carrier
        });
      }
    });
  }

  return result;
});

export const containerSizeSelector = createSelector([selectSortedData], (containers: any) => {
  const result: any[] = [];

  if (containers?.length) {
    const cpy: any = {};
    containers.forEach((item: any) => {
      if (item.size && !cpy[item.size]) {
        cpy[item.size] = true;

        result.push({
          name: item.size,
          value: item.size
        });
      }
    });
  }

  return result;
});
