import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import Component from './component';
import { loadAvailableOutgateJobsAction } from './store/asyncActions';
import { selectFilteredData, selectTableSort } from './store/selectors';
import store from './store';
import { useReactiveDualTrans } from '../ReactiveDualTrans/hooks';

function Container() {
  const reactiveDualTrans = useReactiveDualTrans();

  const dispatch = useAppDispatch();
  const availableOutgateJobs = useSelector(selectFilteredData);
  const tableSort = useSelector(selectTableSort);

  const handleTableSort = (val: any) => {
    dispatch(store.actions.sortTable(val));
  };

  useEffect(() => {
    dispatch(loadAvailableOutgateJobsAction());
  }, []);

  return (
    <>
      <Helmet title="Dual Trans Finder" />
      <Component
        data={availableOutgateJobs}
        onTableSort={handleTableSort}
        tableSort={tableSort}
        departmentsById={reactiveDualTrans.departmentsById}
        matches={reactiveDualTrans.matches}
        driversById={reactiveDualTrans.driversById}
        generatorMatches={reactiveDualTrans.generatorMatches}
        matchSort={reactiveDualTrans.matchSort}
        onMatchSort={reactiveDualTrans.handleMatchSort}
        matchesLoading={reactiveDualTrans.matchesLoading}
        onResetMatches={reactiveDualTrans.resetMatches}
        locationOptions={reactiveDualTrans.locationOptions}
        useNewBundles={reactiveDualTrans.useNewBundles}
        onLoadMatches={reactiveDualTrans.loadMatches}
        onHireStatusesByContainer={reactiveDualTrans.onHireStatusesByContainer}
      />
    </>
  );
}

export default Container;
