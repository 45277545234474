import { createSlice } from '@reduxjs/toolkit';
import getInitialAsyncSliceState from '@client/_blessed/store/utils/getInitialAsyncAtomState';
import { loadAvailableOutgateJobsAction } from './asyncActions';
import { sliceId } from './constants';

function getInitialState(state: any = {}) {
  state.availableOutgateJobs = getInitialAsyncSliceState();
  state.tableSort = {
    column: 'AppointmentTime',
    direction: 'ASC'
  };
  state.filters = {};
  return state;
}

export default createSlice({
  name: sliceId,
  initialState: getInitialState(),
  reducers: {
    sortTable: (state, action) => {
      state.tableSort = action.payload;
    },
    filter: (state, action) => {
      state.filters = action.payload;
    },
    sortMatches: (state, action) => {
      state.matchSort = action.payload;
    }
  },
  extraReducers: (builder) => {
    // load table data
    builder.addCase(loadAvailableOutgateJobsAction.pending, (state) => {
      state.availableOutgateJobs.loading = true;
    });
    builder.addCase(loadAvailableOutgateJobsAction.fulfilled, (state, action) => {
      const data = action.payload?.data || [];
      state.availableOutgateJobs.data = data;
      state.availableOutgateJobs.loading = false;
    });
    builder.addCase(loadAvailableOutgateJobsAction.rejected, (state) => {
      state.availableOutgateJobs.loading = false;
    });
  }
});
