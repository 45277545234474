// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DualTransFinder {
  padding: 28px 31px;
  width: 100%;
  height: 100%;
}

  .DualTransFinder-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .DualTransFinder-table {
    width: 100%;
    overflow-y: scroll;
  }

  .DualTransFinder-table th {
      cursor: pointer;
      text-align: left;
      white-space: nowrap;
    }

  .DualTransFinder-table tbody > tr:hover { 
      cursor: pointer;
      background-color: var(--color-green-1) !important;
    }

  .DualTransFinder-table td {
      padding: 7px;
      margin-right: 2px;
      border: solid 1px #4b4040aa;
    }

  .DualTransFinder-table tbody > tr:nth-child(odd) {
      background-color: var(--color-grayscale-3);
    }

  .DualTransFinder-table-dual {
      min-width: 50px;
    }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/DualTransFinder/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AAqCd;;EAnCE;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,kBAAkB;EA0BpB;;EAxBE;MACE,eAAe;MACf,gBAAgB;MAChB,mBAAmB;IACrB;;EAEA;MACE,eAAe;MACf,iDAAiD;IACnD;;EAEA;MACE,YAAY;MACZ,iBAAiB;MACjB,2BAA2B;IAC7B;;EAEA;MACE,0CAA0C;IAC5C;;EAEA;MACE,eAAe;IACjB","sourcesContent":[".DualTransFinder {\n  padding: 28px 31px;\n  width: 100%;\n  height: 100%;\n\n  &-title {\n    font-size: 20px;\n    font-weight: 500;\n    margin-bottom: 15px;\n  }\n\n  &-table {\n    width: 100%;\n    overflow-y: scroll;\n\n    th {\n      cursor: pointer;\n      text-align: left;\n      white-space: nowrap;\n    }\n\n    tbody > tr:hover { \n      cursor: pointer;\n      background-color: var(--color-green-1) !important;\n    }\n\n    td {\n      padding: 7px;\n      margin-right: 2px;\n      border: solid 1px #4b4040aa;\n    }\n\n    tbody > tr:nth-child(odd) {\n      background-color: var(--color-grayscale-3);\n    }\n\n    &-dual {\n      min-width: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
