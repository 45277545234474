import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import ChassisAndChassisPoolsEditable from '@client/components/ChassisAndChassisPoolsEditable';
import { chassisPoolByIdSelectorFactory } from '@client/_blessed/store/entities';

import ChassisPoolsForm from '../Form/component';

interface Props {
  chassisPoolUuid: string;
  setChassisPoolUuidForEdit: (uuid: string) => void;
}

function EditableChassisPool({ chassisPoolUuid, setChassisPoolUuidForEdit }: Props): ReactElement {
  const chassisPoolInfo = useSelector(chassisPoolByIdSelectorFactory(chassisPoolUuid));

  return (
    <ChassisAndChassisPoolsEditable uuidOrId={chassisPoolUuid} setId={setChassisPoolUuidForEdit}>
      <>
        <div className="chassisPoolsManagement__title">Update chassis pool</div>
        <ChassisPoolsForm chassisPoolInfo={chassisPoolInfo} btnLabel="Update" />
      </>
    </ChassisAndChassisPoolsEditable>
  );
}

export default EditableChassisPool;
